import { FC, ReactElement } from "react"
import InputField from "../../inputs/InputField"
import { useFormikContext } from "formik"
import { FormValuesType } from "./ModalPromoCode"

const NoDepositBonus: FC = (): ReactElement => {
  const { values, touched, errors, handleChange } = useFormikContext<FormValuesType>()
  return (
    <>
      <InputField
        id="bonus_amount"
        title="Bonus Amount"
        altLabelOne={`$${(values.bonus_amount || 0) / 100}`}
        type="number"
        value={values.bonus_amount ?? 0}
        onChange={handleChange}
        invalid={!!(touched.bonus_amount && errors.bonus_amount)}
        altLabelTwo={touched.bonus_amount ? errors.bonus_amount : ""}
      />

      <InputField
        id="wager_requirements"
        title="Wagering Requirements"
        type="number"
        value={values.wager_requirements ?? 0}
        onChange={handleChange}
        invalid={!!(touched.wager_requirements && errors.wager_requirements)}
        altLabelTwo={touched.wager_requirements ? errors.wager_requirements : ""}
      />

      <InputField
        id="days_to_expire"
        title="Days to Expire"
        type="number"
        value={values.days_to_expire ?? 0}
        onChange={handleChange}
        invalid={!!(touched.days_to_expire && errors.days_to_expire)}
        altLabelTwo={touched.days_to_expire ? errors.days_to_expire : ""}
      />
    </>
  )
}

export default NoDepositBonus
