import { REQ } from "constants/api/requests"
import { enumCMSTypes } from "constants/cms"
import { IResponse } from "constants/interfaces"
import { toast } from "react-toastify"
import useApi from "util/api"

import {
  CMSActionResponse,
  CMSTableResponse,
  CMSTextActionResponse,
  CMSTextResponse,
  CreateCMSPayload,
  EditCMSPayload,
  EditCMSTextPayload
} from "./type"
import { Tenant } from "../../types/api"

export const CMSService = {
  async getCMSTable(
    page: number,
    pageSize: number,
    id: number | undefined,
    { q, type, active, tenant }: { q?: string; type?: enumCMSTypes; active?: boolean; tenant?: string },
    sortRow?: string,
    sortOrder?: string
  ): Promise<CMSTableResponse | void> {
    try {
      const params = {
        page,
        pageSize,
        q,
        type,
        tenant,
        active,
        sortRow,
        sortOrder
      }

      const { data } = await useApi.get<IResponse<CMSTableResponse>>(REQ.ADMIN_DASHBOARD.CMS.GET, { params })

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to get cms banner list")
    }
  },

  async createCMS(payload: CreateCMSPayload): Promise<CMSActionResponse | void> {
    const { data } = await useApi.post<IResponse<CMSActionResponse>>(REQ.ADMIN_DASHBOARD.CMS.CREATE, payload)

    try {
      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to create cms banner")
    }
  },

  async editCMS(id: string, payload: EditCMSPayload): Promise<CMSActionResponse | void> {
    const { data } = await useApi.put<IResponse<CMSActionResponse>>(REQ.ADMIN_DASHBOARD.CMS.UPDATE.replace(":id", id), payload)

    try {
      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to update cms banner")
    }
  },

  async getCMSTextData({
    type,
    active,
    tenant
  }: {
    type?: enumCMSTypes
    active?: boolean
    tenant?: string
  }): Promise<CMSTextResponse | void> {
    try {
      const params = {
        type,
        tenant,
        active
      }

      const { data } = await useApi.get<IResponse<CMSTextResponse>>(REQ.ADMIN_DASHBOARD.CMSText.GET, { params })

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to get cms banner list")
    }
  },

  async createCMSText(payload: EditCMSTextPayload): Promise<CMSTextActionResponse | void> {
    const { data } = await useApi.post<IResponse<CMSTextActionResponse>>(REQ.ADMIN_DASHBOARD.CMSText.CREATE, payload)

    if (data?.success) {
      return data?.response
    }
  },

  async editCMSText(id: string, payload: EditCMSTextPayload): Promise<CMSTextActionResponse | void> {
    const { data } = await useApi.put<IResponse<CMSTextActionResponse>>(REQ.ADMIN_DASHBOARD.CMSText.UPDATE.replace(":id", id), payload)

    if (data?.success) {
      return data?.response
    }
  },

  async presignedUrlForCMSImage(fileName: string): Promise<{ url: string } | void> {
    try {
      const { data } = await useApi.post<
        IResponse<{
          url: string
        }>
      >(REQ.ADMIN_DASHBOARD.CMSImage.UPLOAD_IMAGE, { fileName })

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to get presigned url for cms image")
    }
  },

  async deleteCMS(id: string): Promise<CMSActionResponse | void> {
    const { data } = await useApi.delete<IResponse<CMSActionResponse>>(REQ.ADMIN_DASHBOARD.CMS.DELETE.replace(":id", id))

    try {
      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to delete cms banner")
    }
  },

  async getTenants(): Promise<Tenant[] | void> {
    try {
      const { data } = await useApi.get<IResponse<Tenant[]>>(REQ.ADMIN_DASHBOARD.TENANTS.LIST)

      if (data?.success) {
        return data?.response
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err?.message ?? "Failed to get tenant color theme")
      } else {
        console.error("Failed to get tenant color theme")
      }
    }
  },

  async updateTenantData(id: string, payload: Tenant): Promise<Tenant | void> {
    try {
      const { data } = await useApi.put<IResponse<Tenant>>(REQ.ADMIN_DASHBOARD.TENANTS.UPDATE.replace(":id", id), payload)

      if (data?.success) {
        return data?.response
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err?.message ?? "Failed to update tenant color theme")
      } else {
        console.error("Failed to update tenant color theme")
      }
    }
  },

  async getActiveCMSBanners(tenant_id?: string, type?: enumCMSTypes): Promise<CMSActionResponse[] | void> {
    const { data } = await useApi.get<IResponse<CMSActionResponse[]>>(REQ.CMS.GET_ACTIVE, {
      params: { tenant_id, type }
    })

    try {
      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to fetch cms banners")
    }
  }
}
