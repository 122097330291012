import React, { FC, ReactElement, useCallback, useMemo, useState } from "react"
import { Form, Formik } from "formik"
import FormControl from "./FormControl"
import GradientBackgroundFields from "./GradientBackgroundFields"
import { Tenant, TenantColorsData } from "../../../types/api"
import { CMSService } from "../../../services/cms/cms.service"
import { cloneDeep } from "lodash"
import TitleDividersGradientFields from "./TitleDividersGradientFields"
import WalletButtonGradientFields from "./WalletButtonGradientFields"
import TextGradientFields from "./TextGradientFields"
import { formTenantColorsData } from "../../../util/editorsForms"
import ModalsColorFields from "./ModalsColorFields"
import ToolTip from "../components/ToolTip"

const colorsFields: { name: string; title: string; tooltip?: string }[] = [
  {
    name: "primary",
    title: "Primary color",
    tooltip: "Primary color is used for actions buttons borders in the header"
  },
  { name: "secondary", title: "Secondary color", tooltip: "This color is applied to the main page background" },
  { name: "header", title: "Header color", tooltip: "Header color is used for top navigation bar background" },
  { name: "success", title: "Success color", tooltip: "Success color is used for colors withe " },
  { name: "viewMoreColor", title: "View More button color", tooltip: "'VIEW MORE' button color" },
  {
    name: "accordionBorderColor",
    title: "Accordion border color",
    tooltip: "Accordion border color, for vertical left border as in the 'FREQUENTLY ASKED QUESTIONS' accordion"
  },
  {
    name: "iconLinesColor",
    title: "Icon lines color",
    tooltip: "For the icons lines colors, as for the top left menu icon"
  },
  {
    name: "internalNavDotDividerColor",
    title: "Internal navigation dot divider color",
    tooltip: "There are horizontal navigations on some pages, divided by dots. This is the dot color"
  },
  {
    name: "internalNavWrappingBorderColor",
    title: "Internal navigation wrapping border color",
    tooltip: "There are horizontal navigations on some pages and some of them have wrapping borders - this is the color for them"
  },
  {
    name: "cardBorder",
    title: "Card border color",
    tooltip: "Card color is used on the cards borders on the wallet deposit and wallet withdraw page"
  },
  {
    name: "contentButtonTextColor",
    title: "Content button text color",
    tooltip: "The text color of the button with some background color"
  },
  {
    name: "outlinedButtonTextColor",
    title: "Outlined button text color",
    tooltip: "The text color of the button without background color and with wrapping border"
  }
]

type Props = {
  tenant: Tenant
}
const EditorForm: FC<Props> = ({ tenant }): ReactElement => {
  const [loading, setLoading] = useState<"loading" | false>(false)
  const onSubmit = useCallback(async (values: TenantColorsData) => {
    setLoading("loading")
    const updatedTenant: Tenant = cloneDeep(tenant)
    updatedTenant.color_theme = values
    await CMSService.updateTenantData(tenant.id, updatedTenant)
    setLoading(false)
  }, [])

  const initialColorTheme = useMemo(() => formTenantColorsData(tenant), [tenant])

  return (
    <Formik initialValues={initialColorTheme} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <h3>Colors values</h3>
          {colorsFields.map(({ name, title, tooltip }, i) => (
            <FormControl key={i} name={name} title={title} tooltip={tooltip} />
          ))}
          <ModalsColorFields />
          <GradientBackgroundFields />
          <TextGradientFields />
          <TitleDividersGradientFields />
          <div className="relative group">
            <ToolTip text="These are colors for wallet icon button in the header. Gradient colors, hover color and box shadow color" />
            <WalletButtonGradientFields />
            <FormControl name="walletHoverBg" title="Wallet button hover background" />
            <FormControl name="walletBoxShadow" title="Wallet button box shadow color" />
          </div>
          <button className={`btn btn-primary mt-8 ${loading || ""}`} type="submit" disabled={!!loading}>
            Save
          </button>
        </Form>
      )}
    </Formik>
  )
}

export default EditorForm
