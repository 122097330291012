import clsx from "clsx"
import InputField from "components/inputs/InputField"
import Modal from "components/modals/Modal"
import { useProps } from "contexts/PropsContext"
import { useFormik } from "formik"
import { useIsMounted } from "hooks/useIsMounted"
import { toast } from "react-toastify"
import { lowWagerAccess } from "services/user/type"
import userService from "services/user/user.service"
import * as Yup from "yup"

Yup.setLocale({
  mixed: {
    required: "Required Field"
  },
  number: {
    min: "Must be greater than or equal to ${min}"
  }
})

interface FormValuesType {
  amount: number
  wagerRequirements: number
  daysToExpire: number
}

type ModalAddUserBonusProps = {
  onClose: () => void
  open: boolean
  userId: string
  onSuccess: () => void
}
export const ModalAddUserBonus = ({ open, onClose, userId, onSuccess }: ModalAddUserBonusProps) => {
  const isMounted = useIsMounted()

  const { user } = useProps()

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required().min(1),
    wagerRequirements: Yup.number()
      .required()
      .min(lowWagerAccess.includes(user.role) ? 1 : 30),
    daysToExpire: Yup.number().required().min(1)
  })

  const formik = useFormik<FormValuesType>({
    initialValues: {
      amount: 0,
      wagerRequirements: 10,
      daysToExpire: 7
    },
    enableReinitialize: true,
    onSubmit: async (values: FormValuesType, formikHelpers): Promise<void> => {
      try {
        const { daysToExpire, amount, wagerRequirements } = values
        await userService.claimCustomBonus({ daysToExpire, amount, wagerRequirements, userId })
        toast.success("Bonus added successfully!")
        onClose()
        onSuccess()
      } catch (err: any) {
        toast.error(err?.data?.message ?? err?.message ?? "Failed")
        if (isMounted()) {
          formikHelpers.setStatus({ success: false })
          formikHelpers.setSubmitting(false)
        }
      }
    },
    validationSchema
  })

  return (
    <Modal open={open} handler={onClose}>
      <div className="relative flex flex-col justify-between modal-box w-auto">
        <div className="flex justify-center">
          <div className="btn-group">
            <button className="btn btn-active">Custom Bonus</button>
            <button className="btn">Deposit Bonus Offer</button>
          </div>
        </div>
        <div className="flex flex-col pt-5">
          <InputField
            name="amount"
            type="number"
            title="Bonus Amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            altLabelOne={`$${formik.values.amount / 100}`}
            invalid={!!(formik.errors.amount && formik.touched.amount)}
            altLabelTwo={formik.touched.amount ? formik.errors.amount : ""}
          />
          <InputField
            name="wagerRequirements"
            type="number"
            title="Wagering Requirements"
            value={formik.values.wagerRequirements}
            onChange={formik.handleChange}
            invalid={!!(formik.errors.wagerRequirements && formik.touched.wagerRequirements)}
            altLabelTwo={formik.touched.wagerRequirements ? formik.errors.wagerRequirements : ""}
          />
          <InputField
            name="daysToExpire"
            type="number"
            title="Days to Expire"
            value={formik.values.daysToExpire}
            onChange={formik.handleChange}
            invalid={!!(formik.errors.daysToExpire && formik.touched.daysToExpire)}
            altLabelTwo={formik.touched.daysToExpire ? formik.errors.daysToExpire : ""}
          />
          <div className="modal-action flex items-center space-x-4">
            <button className="btn btn-secondary flex-1" onClick={onClose} disabled={formik.isSubmitting}>
              Cancel
            </button>
            <button
              className={clsx("btn btn-primary flex-1", { loading: formik.isSubmitting })}
              onClick={formik.submitForm}
              disabled={formik.isSubmitting}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
