import React, { FC, ReactElement } from "react"
import { FieldArray, FormikProps } from "formik" // eslint-disable-line
import { TenantConfigData } from "../../../types/api"
import InputField from "../../inputs/InputField"
import TrashIcon from "../../../assets/icons/trash-icon.svg"

const SealsFields: FC = (): ReactElement => (
  <FieldArray
    name="seals"
    render={({ form, push, remove }) => (
      <div className="mt-5 pb-4 border-b border-gray-400">
        <h4>Seals</h4>
        {(form as FormikProps<TenantConfigData>).values.seals.map((_, index) => {
          return (
            <div key={index} className="flex gap-2">
              <div className="flex items-center grow gap-2">
                <span>{index + 1}</span>
                <InputField
                  name={`seals.${index}`}
                  value={(form as FormikProps<TenantConfigData>).values.seals[index]}
                  onChange={form.handleChange}
                />
              </div>
              <button onClick={() => remove(index)} type="button">
                <img className="w-5 h-5" src={TrashIcon} alt="Trash icon" />
              </button>
            </div>
          )
        })}
        <button type="button" onClick={() => push("")} className="btn btn-success mt-4">
          Add seal
        </button>
      </div>
    )}
  />
)

export default SealsFields
