import { FC, ReactElement } from "react"
import DropdownMenu from "../../inputs/DropdownMenu"
import { enumPromoTypes } from "../../../constants/promo-codes"
import { useFormikContext } from "formik"
import { FormValuesType } from "./ModalPromoCode"

type Props = {
  data?: any
  promoBonuses?: any
  promoBonusesSpins?: any
}
const DepositFreeSpins: FC<Props> = ({ data, promoBonuses, promoBonusesSpins }): ReactElement => {
  const { values, touched, errors, handleChange } = useFormikContext<FormValuesType>()
  const bonusId = values?.bonus_id || data?.bonus?.id
  return (
    <DropdownMenu
      id="bonus_id"
      name="bonus_id"
      title="Bonus Offer"
      options={(values.type === enumPromoTypes.FREESPINS ? promoBonusesSpins : promoBonuses).map((pBonus: any) => ({
        name: pBonus.title,
        value: pBonus.id
      }))}
      value={(bonusId || values.bonus_id) ?? ""}
      onChange={handleChange}
      placeholder="Please choose an option"
      invalid={Boolean(touched.bonus_id && errors.bonus_id)}
      altLabelOne={touched.bonus_id ? errors.bonus_id : ""}
    />
  )
}

export default DepositFreeSpins
