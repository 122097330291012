import clsx from "clsx"
import { ChangeEvent, FC, useEffect, useState } from "react"

interface IProps {
  id?: string
  title?: string
  invalid?: boolean
  setFieldValue?: any
  placeholder?: string
  disabled?: boolean
  name?: string
  altLabelOne?: string
  altLabelTwo?: string
  altLabelThree?: string
  accept?: string
}

const FileUploadNoSaving: FC<IProps> = (props) => {
  const { id, title, invalid, setFieldValue, placeholder, disabled, name, altLabelOne, altLabelTwo, altLabelThree, accept } = props
  const [value, setValue] = useState("")

  useEffect(() => {
    setValue("")
  }, [id])

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      try {
        setFieldValue(id, e.currentTarget.files[0])
      } catch (err) {
        console.error("Debug handleUploadFile", err)
      }
    }
  }

  return (
    <div className="w-full form-control">
      {Boolean(title) && (
        <div className="flex w-full justify-between items-center">
          <label className="label flex w-full justify-between">
            <span className={clsx("label-text", { "text-error": invalid })}>{title}</span>
            <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelOne}</span>
          </label>
        </div>
      )}
      <input
        type="file"
        value={value}
        onChange={handleUploadFile}
        placeholder={placeholder}
        className={`w-full file-input file-input-bordered ${invalid ? "input-error" : ""}`}
        disabled={disabled}
        accept={accept ?? "image/*"}
        {...(id && { id })}
        {...(name && { name })}
      />
      <label>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelTwo}</span>
        <span className={`label-text-alt ${invalid ? "text-error" : ""}`}>{altLabelThree}</span>
      </label>
    </div>
  )
}

export default FileUploadNoSaving
