import React, { FC, ReactElement } from "react"
import InputField from "../../inputs/InputField"
import { useFormikContext } from "formik"
import { TenantConfigData } from "../../../types/api"
import TitledCheckbox from "../../inputs/TitledCheckbox"

const RegularFields: FC = (): ReactElement => {
  const { values, handleChange } = useFormikContext<TenantConfigData>()
  return (
    <div className="my-10">
      <InputField name="sealANJText" value={values.sealANJText} onChange={handleChange} title="Seal ANJ Text" />
      <InputField name="noSealText" value={values.noSealText} onChange={handleChange} title="No Seal Text" />
      <div className="flex my-5">
        <div className="w-[15%] mr-40">
          <TitledCheckbox id="isDisableFiat" title="Disable FIAT" checked={values.isDisableFiat} onChange={handleChange} />
        </div>
        <div className="w-[15%]">
          <TitledCheckbox id="isDisableCrypto" title="Disable crypto" checked={values.isDisableCrypto} onChange={handleChange} />
        </div>
      </div>
    </div>
  )
}

export default RegularFields
