import { FC, ReactElement } from "react"
import ArrowIcon from "../../icons/ArrowIcon"
import { useTenantsData, UseTenantsDataReturn } from "../../../hooks/useTenantsData"
import EditorForm from "./EditorForm"

const CMSTenantConfigEditor: FC = (): ReactElement => {
  const { tenants, toggleEditForm, tenantId } = useTenantsData()
  return (
    <div>
      {tenants.map((tenant) => (
        <div
          key={tenant.id}
          className="
          first:rounded-t-xl border border-b-0 last:border-b-2 last:rounded-b-xl border-gray-200 focus:ring-4
          focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100
          dark:hover:bg-gray-800 gap-3
          "
        >
          <h2>
            <button
              type="button"
              className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500"
              onClick={() => toggleEditForm(tenant.id)}
            >
              <span>{tenant.id}</span>
              <ArrowIcon rotate={tenant.id === tenantId} />
            </button>
          </h2>
          <div className={tenant.id !== tenantId ? "hidden" : ""}>
            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
              <EditorForm tenant={tenant} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CMSTenantConfigEditor
