import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { SketchPicker } from "react-color"

type Props = {
  title?: string
  value: string
  onChange: any
  name: string
  altLabelOne?: string
  altLabelTwo?: string
  altLabelThree?: string
}

const InputField: FC<Props> = ({ title, value, onChange, name, altLabelOne, altLabelTwo, altLabelThree }) => {
  const [color, setColor] = useState<string>(value)
  const [showPicker, setShowPicker] = useState(false)
  const pickerRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if ((event.target as HTMLElement).dataset.name !== name && pickerRef.current && !pickerRef.current?.contains(event.target as Node)) {
        setShowPicker(false)
      }
    },
    [pickerRef]
  )

  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [showPicker, handleClickOutside])
  useEffect(() => {
    onChange(color)
  }, [color])

  const topValue = useMemo(() => (title ? "84px" : "64px"), [title])
  return (
    <div className="w-full form-control relative">
      <label className="label" htmlFor={name}>
        <span className="label-text">{title}</span>
        <span className="label-text-alt">{altLabelOne}</span>
      </label>
      <div
        className="w-full input input-bordered cursor-pointer"
        style={{ backgroundColor: value }}
        onClick={() => setShowPicker(!showPicker)}
        data-name={name}
      />
      {showPicker && (
        <div className="absolute z-10 left-0 bg-white rounded-btn" style={{ top: topValue }} ref={pickerRef}>
          {/*@ts-ignore*/}
          <SketchPicker disableAlpha color={color} onChange={(color) => setColor(color.hex)} width="35vw" />
        </div>
      )}

      <label>
        <span className="label-text-alt">{altLabelTwo}</span>
        <span className="label-text-alt">{altLabelThree}</span>
      </label>
    </div>
  )
}

export default InputField
