import { enumTenant } from "constants/bonus"
import { enumCMSTypes } from "constants/cms"
import { Form, Formik, FormikHelpers } from "formik" // eslint-disable-line
import React, { FC, ReactElement, useCallback } from "react"
import * as Yup from "yup"

import Modal from "../Modal"
import { MainFields } from "./MainFields"
import { ExtraBanners } from "./ExtraBanners"
import { BannerLanguage, CreateCMSPayload } from "../../../services/cms/type"
import { ActiveStepType } from "../../../views/CMSPage"
import DefaultBanners from "./DefaultBanners"

export interface FormValuesType {
  id?: string
  active: boolean
  tenant_id: string
  banner_image: string | File
  banner_image_animation: string | File
  is_banner_image_animation: boolean
  banner_image_mobile: string | File
  banner_image_mobile_animation: string | File
  is_banner_image_mobile_animation: boolean
  name: string
  headline: string
  subheadline: string
  link: string
  order?: number | string
  banners_languages: BannerLanguage[]
  jackpots: boolean
}

interface IProps {
  data?: CreateCMSPayload | null
  open: boolean
  handler: any
  onSubmit: (values: any) => void
  type: enumCMSTypes
  onDelete?: (id: string) => void
  activeSteps: ActiveStepType[]
}

const langBanner = Yup.object({
  lang: Yup.string().required("This field is required"),
  desktop: Yup.string().nullable().required("This field is required"),
  mobile: Yup.string().nullable()
})
const validationSchema = (type: enumCMSTypes) => {
  return Yup.object().shape({
    tenant_id: Yup.mixed().oneOf([enumTenant.Slotwhales, enumTenant.Kingcasi, enumTenant.Blockbets]).required(),
    name: Yup.string().required("This field is required"),
    banner_image: Yup.string().nullable().required("This field is required"),
    banners_languages: Yup.array().of(langBanner),
    order:
      type === enumCMSTypes.HOMEPAGE_BANNER
        ? Yup.number()
            .positive("Should be over 0 and less than 4")
            .max(3, "Should be over 0 and less than 4")
            .required("This field is required")
        : Yup.number().notRequired()
  })
}

const ModalCMS: FC<IProps> = ({ data, open, handler, onSubmit, type, onDelete, activeSteps }): ReactElement => {
  const onSubmitHandler = useCallback(
    (values: FormValuesType, formikHelpers: FormikHelpers<any>): void => {
      if (
        type === enumCMSTypes.HOMEPAGE_BANNER &&
        values.active &&
        activeSteps.length > 0 &&
        activeSteps.find((item) => item.order === values.order && item.id !== values.id)
      ) {
        formikHelpers.setFieldError(
          "order",
          `You already have the active banner with the order value - ${values.order}.
          Impossible to have multiple active banners.
          Change the order value, or make the banner no active, or deactivate the active banner with the same order value`
        )
        return
      }
      onSubmit({ ...values, type })
      handler()
    },
    [type, activeSteps]
  )

  return (
    <Modal open={open} handler={handler}>
      <div className="relative flex flex-col justify-between modal-box sm:min-w-[700px]">
        <Formik
          initialValues={{
            id: data?.id ?? "",
            active: data?.active ?? false,
            tenant_id: data?.tenant_id ?? enumTenant.Slotwhales,
            name: data?.name ?? "",
            banner_image: data?.banner_image ?? "",
            banner_image_animation: data?.banners_languages.find((b) => b.lang === "en")?.desktop_animation ?? "",
            is_banner_image_animation: !!data?.banners_languages.find((b) => b.lang === "en")?.is_desktop_animation,
            banner_image_mobile: data?.banner_image_mobile ?? "",
            banner_image_mobile_animation: data?.banners_languages.find((b) => b.lang === "en")?.mobile_animation ?? "",
            is_banner_image_mobile_animation: !!data?.banners_languages.find((b) => b.lang === "en")?.is_mobile_animation,
            headline: data?.headline ?? "",
            subheadline: data?.subheadline ?? "",
            link: data?.link ?? "",
            order: data?.order ?? "",
            banners_languages: data?.banners_languages.slice(1) ?? [],
            jackpots: !!data?.banners_languages.find((b) => b.lang === "en")?.jackpots
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema(type)}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <DefaultBanners type={type} />
              <MainFields type={type} />
              <ExtraBanners type={type} />
              <div className={`modal-action flex ${data && onDelete && "justify-between"}`}>
                {data && onDelete && (
                  <button className="btn btn-error" onClick={() => onDelete(data.id as string)} type="button">
                    Delete
                  </button>
                )}
                <div className="flex gap-1">
                  <button className="btn btn-secondary" onClick={handler} type="button">
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default ModalCMS
