import React, { FC, ReactElement } from "react"
import { tableReferrers } from "../constants/data"
import { useTranslation } from "react-i18next"
import referralsService from "../services/referrals/referrals.service"
import { useProps } from "../contexts/PropsContext"
import TableAccordion from "../components/dropdowns/TableAccordion"

export const Referrals: FC = (): ReactElement => {
  const { user } = useProps()
  const { t } = useTranslation(["landing"], { useSuspense: false })
  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      {user && (
        <div className="flex items-center justify-center text-xl text-black dark:text-white">
          {t("welcome", { value: user?.display_name })}
        </div>
      )}
      <TableAccordion
        title={t("referrers")}
        handler={referralsService.getReferrersTable}
        columns={tableReferrers}
        // content={ // TODO: we have to put here nested accordion with other data
        //   <TableAccordion
        //     title={t("referrals")}
        //     handler={referralsService.getReferralsTable}
        //     columns={tableReferrals}
        //     content={<div>Hello</div>}
        //   />
        // }
      />
    </div>
  )
}

export default Referrals
