import MultiDatesPicker from "components/date/MultiDatesPicker"
import { InputToggle } from "components/inputs/InputToggle"
import { bonusTypeOptions, enumTenant, tenantOptions } from "constants/bonus"
import { enumCMSTypes } from "constants/cms"
import { ISelectOption } from "constants/interfaces"
import { useFormik } from "formik"
import { useIsMounted } from "hooks/useIsMounted"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { BonusService } from "services/bonus/bonus.service"
import { CMSService } from "services/cms/cms.service"
import { PaymentServiceAdmin } from "services/payment/payment.service"
import { BonusType } from "types/api"
import { shallowChanged } from "util/shallowChanged"
import * as Yup from "yup"

import DropdownMenu from "../inputs/DropdownMenu"
import InputField from "../inputs/InputField"
import Modal from "./Modal"

Yup.setLocale({
  mixed: {
    required: "Required Field"
  },
  number: {
    min: "Must be greater than or equal to ${min}"
  }
})

interface FormValuesType {
  type: BonusType
  title: string
  bonus_percent: number
  active: boolean
  days_to_expire: number
  wager_requirements: number
  max_usage_per_user: number
  max_bonus_amount: number
  min_deposit: number
  // zero_out_threshold: number
  // bet_max_limit: number
  // withdraw_max_limit: number
  submit?: any
  tenant_id: string
  banner_image: string
  schedule?: string[]
  schedule_timezone?: string
  currencies: {
    currency: string
    min_deposit: number
    max_bonus_amount: number
  }[]
  event: string
  freespinsAmount: number
  gameIdentifier: string
  pricePerSpin: number
  additional_bonus_id: string | null
}

type ModalBonusProps = {
  open: boolean
  data: any
  handler: any
}

const availableCurrencies = ["NOK", "EUR", "PLN", "USD", "CAD", "JPY", "BRL", "SEK", "TRY", "RUB", "PHP", "VND", "DKK"]

export const ModalBonus = ({ open, data, handler }: ModalBonusProps) => {
  const type = data ? "edit" : "add"
  const isMounted = useIsMounted()
  const [banners, setBanners] = useState<ISelectOption[]>([])
  const [fsBonuses, setFSBonuses] = useState<any[]>([])

  useEffect(() => {
    const getBonusesList = async () => {
      const bonuses = await BonusService.getBonusesList(1, 100, undefined, { type: BonusType.FREESPINS }, "created_at", "desc")
      setFSBonuses(bonuses?.data || [])
    }

    if (open) getBonusesList()
  }, [open])

  const formik = useFormik<FormValuesType>({
    initialValues: {
      type: data?.type ?? "",
      title: data?.title ?? "",
      bonus_percent: data?.bonus_percent ?? 100,
      min_deposit: data?.min_deposit ?? 0,
      max_usage_per_user: data?.max_usage_per_user ?? 1,
      max_bonus_amount: data?.max_bonus_amount ?? 0,
      active: data?.active ?? false,
      days_to_expire: data?.days_to_expire ?? 7,
      wager_requirements: data?.wager_requirements ?? 10,
      tenant_id: data?.tenant_id ?? enumTenant.Blockbets,
      banner_image: data?.banner_image ?? "",
      schedule: data?.schedule ? data.schedule?.split(",") : [],
      schedule_timezone: data?.schedule_timezone ?? "",
      currencies: (() => {
        if (Array.isArray(data?.currencies)) return data.currencies
        try {
          return JSON.parse(data?.currencies) ?? []
        } catch {
          return []
        }
      })(),
      event: data?.event || "",
      freespinsAmount: data?.freespinsAmount ?? 0,
      gameIdentifier: data?.gameIdentifier ?? "",
      pricePerSpin: data?.pricePerSpin ?? 0,
      additional_bonus_id: data?.additional_bonus_id ?? null
    },
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      try {
        if (type === "add") {
          const newValues = {
            ...values,
            schedule: values.schedule?.join(",")
          }
          if (newValues.additional_bonus_id === "") newValues.additional_bonus_id = null
          await BonusService.addBonus(newValues)
          handler()
          return toast.success("Bonus added successfully")
        }

        if (!formik.dirty) return
        const changedValues = shallowChanged(values, formik.initialValues)
        const newValues = {
          ...changedValues,
          schedule: changedValues.schedule?.join(",")
        }
        if (newValues.additional_bonus_id === "") newValues.additional_bonus_id = null
        await BonusService.editBonus(data.id, newValues)
        handler()
        return toast.success("Bonus edited successfully")
      } catch (err: any) {
        if (isMounted()) {
          helpers.setStatus({ success: false })
          // helpers.setErrors({ submit: err?.message ?? "Failed" })
          toast.error("Failed")
          helpers.setSubmitting(false)
        }
      }
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required(),
      title: Yup.string().required(),
      bonus_percent: Yup.number()
        .required()
        .when("type", {
          is: "FREESPINS",
          then: Yup.number().min(0).default(0),
          otherwise: Yup.number().min(1)
        }),
      min_deposit: Yup.number().required().min(0),
      max_usage_per_user: Yup.number().required().min(1),
      max_bonus_amount: Yup.number().required().min(0),
      active: Yup.boolean().required(),
      days_to_expire: Yup.number().required().min(0),
      wager_requirements: Yup.number().required().min(1),
      tenant_id: Yup.mixed().oneOf([enumTenant.Slotwhales, enumTenant.Kingcasi, enumTenant.Blockbets]).required(),
      currencies: Yup.array()
        .of(
          Yup.object().shape({
            currency: Yup.string().required("Currency is required"),
            min_deposit: Yup.number().min(0).required("Min deposit required"),
            max_bonus_amount: Yup.number().min(0).required("Max bonus required")
          })
        )
        .default([]),
      event: Yup.string().optional().nullable().default(""),
      freespinsAmount: Yup.number().nullable().min(0).default(0),
      gameIdentifier: Yup.string().nullable().default(null)
    })
  })

  useEffect(() => {
    const fetchBanners = async () => {
      const res: any = await CMSService.getCMSTable(1, 100, undefined, {
        type: enumCMSTypes.BONUS_BANNER,
        active: true,
        tenant: formik.values.tenant_id
      })
      if (res.data) {
        setBanners(
          res.data.map((item: any) => ({
            value: item.id,
            name: item.name
          }))
        )
      }
    }
    fetchBanners()
  }, [formik.values.tenant_id])

  const onGetCurRates = async (currency: string) => {
    const cRates = await PaymentServiceAdmin.getCurRates("USD")
    if (cRates?.rates && cRates.rates[currency]) {
      return cRates.rates[currency]
    }
    return 1
  }

  const addCurrency = () => {
    const updatedCurrencies = [
      ...formik.values.currencies,
      {
        currency: "",
        min_deposit: formik.values.min_deposit,
        max_bonus_amount: formik.values.max_bonus_amount
      }
    ]
    formik.setFieldValue("currencies", updatedCurrencies)
  }

  const removeCurrency = (index: number) => {
    const updatedCurrencies = formik.values.currencies.filter((_, i) => i !== index)
    formik.setFieldValue("currencies", updatedCurrencies)
  }

  const updateCurrencyField = async (index: number, field: keyof FormValuesType["currencies"][0], value: any) => {
    const updatedCurrencies = [...formik.values.currencies]
    updatedCurrencies[index] = { ...updatedCurrencies[index], [field]: value }

    if (field === "currency" && value) {
      const exchangeRate = await onGetCurRates(value)
      updatedCurrencies[index].min_deposit = formik.values.min_deposit * exchangeRate
      updatedCurrencies[index].max_bonus_amount = formik.values.max_bonus_amount * exchangeRate
    }

    formik.setFieldValue("currencies", updatedCurrencies)
  }

  return (
    <Modal open={open} handler={handler}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col justify-between modal-box max-w-full">
          <p className="text-white pb-5">General</p>
          <div className="flex flex-col overflow-y-auto -mx-[1.5rem] px-[1.5rem]">
            <div className="grid grid-cols-3 gap-2">
              <DropdownMenu
                id="tenant_id"
                title="Tenant"
                noAllValueText
                disableEmptyOption
                options={tenantOptions}
                value={formik.values.tenant_id}
                onChange={formik.handleChange}
                invalid={Boolean(formik.touched.tenant_id && formik.errors.tenant_id)}
                altLabelOne={formik.touched.tenant_id ? formik.errors.tenant_id : ""}
              />
              <DropdownMenu
                id="type"
                title="Bonus Type"
                noAllValueText
                options={bonusTypeOptions}
                value={formik.values.type}
                onChange={formik.handleChange}
                invalid={Boolean(formik.touched.type && formik.errors.type)}
                altLabelOne={formik.touched.type ? formik.errors.type : ""}
              />
              <InputField
                id="title"
                title="Bonus Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                invalid={Boolean(formik.touched.title && formik.errors.title)}
                altLabelTwo={formik.touched.title ? formik.errors.title : ""}
              />
              <InputField
                type="number"
                id="bonus_percent"
                title="Bonus Percent"
                value={formik.values.bonus_percent}
                onChange={formik.handleChange}
                invalid={Boolean(formik.touched.bonus_percent && formik.errors.bonus_percent)}
                altLabelTwo={formik.touched.bonus_percent ? formik.errors.bonus_percent : ""}
              />
              <InputField
                type="number"
                id="min_deposit"
                title="Min Deposit Amount"
                value={formik.values.min_deposit}
                onChange={formik.handleChange}
                altLabelOne={`$${formik.values.min_deposit / 100}`}
                invalid={Boolean(formik.touched.min_deposit && formik.errors.min_deposit)}
                altLabelTwo={formik.touched.min_deposit ? formik.errors.min_deposit : ""}
              />
              <InputField
                type="number"
                id="max_bonus_amount"
                title="Max Bonus Amount"
                value={formik.values.max_bonus_amount}
                onChange={formik.handleChange}
                altLabelOne={`$${formik.values.max_bonus_amount / 100}`}
                invalid={Boolean(formik.touched.max_bonus_amount && formik.errors.max_bonus_amount)}
                altLabelTwo={formik.touched.max_bonus_amount ? formik.errors.max_bonus_amount : ""}
              />
              <InputField
                type="number"
                id="max_usage_per_user"
                title="Max Usage Per User"
                disabled
                value={formik.values.max_usage_per_user}
                onChange={formik.handleChange}
                invalid={Boolean(formik.touched.max_usage_per_user && formik.errors.max_usage_per_user)}
                altLabelTwo={formik.touched.max_usage_per_user ? formik.errors.max_usage_per_user : ""}
              />
              <InputField
                type="number"
                id="wager_requirements"
                title="Wager Requirements"
                value={formik.values.wager_requirements}
                onChange={formik.handleChange}
                invalid={Boolean(formik.touched.wager_requirements && formik.errors.wager_requirements)}
                altLabelTwo={formik.touched.wager_requirements ? formik.errors.wager_requirements : ""}
              />
              <InputField
                type="number"
                id="days_to_expire"
                title="Days to Expire"
                value={formik.values.days_to_expire}
                onChange={formik.handleChange}
                invalid={Boolean(formik.touched.days_to_expire && formik.errors.days_to_expire)}
                altLabelTwo={formik.touched.days_to_expire ? formik.errors.days_to_expire : ""}
              />
              <MultiDatesPicker
                id="schedule"
                title="Schedule"
                value={formik.values.schedule}
                onChange={formik.setFieldValue}
                invalid={Boolean(formik.touched.schedule && formik.errors.schedule)}
                altLabelTwo={formik.touched.schedule ? formik.errors.schedule : ""}
                data={data}
              />
              <DropdownMenu
                id="banner_image"
                title="Banner"
                noAllValueText
                options={banners as any}
                value={formik.values.banner_image}
                onChange={formik.handleChange}
                invalid={Boolean(formik.touched.banner_image && formik.errors.banner_image)}
                altLabelOne={formik.touched.banner_image ? formik.errors.banner_image : ""}
              />
              <div className="flex items-center">
                <InputToggle id="active" title="Active" checked={formik.values.active} onChange={formik.handleChange} />
              </div>

              {formik.values.type === "FREESPINS" && (
                <>
                  <InputField
                    type="number"
                    id="pricePerSpin"
                    title="Price Per Spin"
                    value={formik.values.pricePerSpin}
                    onChange={formik.handleChange}
                    invalid={Boolean(formik.touched.pricePerSpin && formik.errors.pricePerSpin)}
                    altLabelTwo={formik.touched.pricePerSpin ? formik.errors.pricePerSpin : ""}
                    altLabelOne={`$${formik.values.pricePerSpin / 100}`}
                  />
                  <InputField
                    type="number"
                    id="freespinsAmount"
                    title="Free Spins Amount"
                    value={formik.values.freespinsAmount}
                    onChange={formik.handleChange}
                    invalid={Boolean(formik.touched.freespinsAmount && formik.errors.freespinsAmount)}
                    altLabelTwo={formik.touched.freespinsAmount ? formik.errors.freespinsAmount : ""}
                  />
                  <InputField
                    id="gameIdentifier"
                    title="Game Identifier"
                    value={formik.values.gameIdentifier}
                    onChange={formik.handleChange}
                    invalid={Boolean(formik.touched.gameIdentifier && formik.errors.gameIdentifier)}
                    altLabelTwo={formik.touched.gameIdentifier ? formik.errors.gameIdentifier : ""}
                  />
                  <InputField
                    id="event"
                    title="Event"
                    value={formik.values.event}
                    onChange={formik.handleChange}
                    invalid={Boolean(formik.touched.event && formik.errors.event)}
                    altLabelTwo={formik.touched.event ? formik.errors.event : ""}
                  />
                </>
              )}

              {formik.values.type !== "FREESPINS" && (
                <DropdownMenu
                  id="additional_bonus_id"
                  title="Related Bonus"
                  noAllValueText
                  options={fsBonuses.map((item: any) => ({ name: item.title, value: item.id }))}
                  value={formik.values.additional_bonus_id}
                  onChange={formik.handleChange}
                  invalid={Boolean(formik.touched.additional_bonus_id && formik.errors.additional_bonus_id)}
                  altLabelOne={formik.touched.additional_bonus_id ? formik.errors.additional_bonus_id : ""}
                />
              )}
            </div>
            <div className="flex flex-col gap-4 pt-4">
              <div>
                <p className="text-white">Currencies</p>
                <div className="text-sm">Add a currency to make the bonus exclusive for specified currencies</div>
              </div>
              {(formik.values.currencies ?? []).map((currencyOption, index) => (
                <div key={index} className="flex gap-4 mb-4">
                  <DropdownMenu
                    title="Currency"
                    options={availableCurrencies.map((curr) => ({ name: curr, value: curr }))}
                    value={currencyOption.currency}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateCurrencyField(index, "currency", e.target.value)}
                  />
                  <InputField
                    type="number"
                    title="Min Deposit"
                    value={currencyOption.min_deposit}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateCurrencyField(index, "min_deposit", +e.target.value)}
                    altLabelOne={
                      currencyOption.currency
                        ? `${currencyOption.min_deposit / 100} ${currencyOption.currency}`
                        : `$${formik.values.min_deposit / 100} USD`
                    }
                  />
                  <InputField
                    type="number"
                    title="Max Bonus"
                    value={currencyOption.max_bonus_amount}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateCurrencyField(index, "max_bonus_amount", +e.target.value)}
                    altLabelOne={
                      currencyOption.currency
                        ? `${currencyOption.max_bonus_amount / 100} ${currencyOption.currency}`
                        : `$${formik.values.max_bonus_amount / 100} USD`
                    }
                  />
                  <button type="button" className="btn btn-danger self-end" onClick={() => removeCurrency(index)}>
                    Remove
                  </button>
                </div>
              ))}

              <button type="button" className="btn btn-secondary mt-2" onClick={addCurrency}>
                Add Currency
              </button>
            </div>
            <div className="grid grid-cols-3 gap-2 pt-8 pb-36"></div>
          </div>
          <div className="modal-action">
            <button className="btn btn-secondary" onClick={handler} type="button">
              Cancel
            </button>
            <button className="btn btn-primary" type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting && <span className="loading loading-spinner" />}
              {type === "add" ? "Add" : "Save"}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
