import { FC, ReactElement } from "react"
import { FieldArray, FormikProps } from "formik" // eslint-disable-line
import { TenantColorsData } from "../../../types/api"
import ToolTip from "../components/ToolTip"
import InputColor from "../../inputs/InputColor"

const GradientBackgroundFields: FC = (): ReactElement => (
  <FieldArray
    name="gradient.main"
    render={({ form }) => (
      <div className="mt-10 relative group">
        <ToolTip text="Theses are the main gradient values, for example for primary buttons" />
        <h4>Primary gradient colors</h4>
        {(form as FormikProps<TenantColorsData>).values.gradient.main.map((_, index) => (
          <div key={index} className="flex items-end">
            <span className="mr-3">{index + 1}</span>
            <InputColor
              name={`gradient.main.${index}`}
              value={(form as FormikProps<TenantColorsData>).values.gradient.main[index]}
              onChange={(value: string) => form.setFieldValue(`gradient.main.${index}`, value)}
            />
          </div>
        ))}
      </div>
    )}
  />
)

export default GradientBackgroundFields
