import { FC, ReactElement, useMemo } from "react"
import ImagePreview from "./index"
import FileUploadNoSaving from "../inputs/FileUploadNoSaving"

type Props = {
  value: string | File
  setFieldValue: ((args: any) => void) | ((field: string, value: any, shouldValidate?: boolean | undefined) => void)
  title?: string
  id?: string
  isInvalid?: boolean
  altLabelOne?: string
  altLabelTwo?: string
  altLabelThree?: string
  onlyName?: boolean
  accept?: string
}
const UploadPreview: FC<Props> = ({
  value,
  setFieldValue,
  title,
  id,
  isInvalid,
  altLabelOne,
  altLabelTwo,
  altLabelThree,
  onlyName,
  accept
}): ReactElement => (
  <div className="flex flex-row space-x-4 mt-3 min-w-fit">
    {value ? (
      <div>
        <span className="label-text">{title}</span>
        <ImagePreview id={id} value={value} setFieldValue={setFieldValue} onlyName={onlyName} />
      </div>
    ) : (
      <FileUploadNoSaving
        id={id}
        title={title}
        setFieldValue={setFieldValue}
        invalid={isInvalid}
        altLabelOne={altLabelOne}
        altLabelTwo={altLabelTwo}
        altLabelThree={altLabelThree}
        accept={accept}
      />
    )}
  </div>
)

export default UploadPreview
