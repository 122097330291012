import clsx from "clsx"
import DropdownMenu from "components/inputs/DropdownMenu"
import ModalCMS from "components/modals/ModalCMS"
import Table from "components/tables/Table"
import { tenantOptions } from "constants/bonus"
import { enumCMSTypes, tableCMSColumns } from "constants/cms"
import { SortOrderBy, SortOrderType } from "constants/eums"
import { useProps } from "contexts/PropsContext"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { CMSService } from "services/cms/cms.service"
import { CMSActionResponse, CreateCMSPayload } from "services/cms/type"

import CMSColorEditor from "../components/editors/CMSColorEditor"
import CMSTenantConfigEditor from "../components/editors/CMSTenantConfigEditor"
import CMSTextEditor from "../components/editors/CMSTextEditor"
import { FormValuesType } from "../components/modals/ModalCMS/ModalCMS"
import SearchInput from "../components/search/SearchInput"
import { prepareData } from "../util/api"

export type PayloadToCreateEditCMSPayload =
  | (FormValuesType & { type: enumCMSTypes })
  | (FormValuesType & {
      type: enumCMSTypes
      id: string
    })

export type ActiveStepType = {
  order?: number
  id: string
}

const CMSPage = () => {
  const { t } = useTranslation(["landing"], { useSuspense: false })

  const [editRow, setEditRow] = useState<CreateCMSPayload | null>(null)
  const [searchValue, setSearchValue] = useState<string>("")
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [tableUpdatedAt, setTableUpdatedAt] = useState<Date | number>(Date.now())
  const [currentTab, setCurrentTab] = useState<enumCMSTypes>(enumCMSTypes.HOMEPAGE_BANNER)
  const [tenant, setTenant] = useState<string>("")
  const [activeBanners, setActiveBanners] = useState<CMSActionResponse[]>([])
  const { user } = useProps()

  const onEditRow = (row: any): void => {
    setEditRow(row)
    setOpenEditModal(true)
  }

  const editRowHandler = useCallback((): void => {
    setEditRow(null)
    setOpenEditModal(false)
  }, [])

  const createPromoCodeHandler = (): void => {
    setOpenCreateModal(false)
  }

  const onEditModalSubmit = useCallback(async ({ id, ...data }: PayloadToCreateEditCMSPayload): Promise<void> => {
    const updatedData = await prepareData(data)
    if (updatedData && id) {
      await CMSService.editCMS(id, updatedData)
      setTableUpdatedAt(Date.now())
    }
  }, [])

  const onDelete = useCallback(async (id: string | undefined) => {
    if (id) {
      await CMSService.deleteCMS(id)
      editRowHandler()
      setTableUpdatedAt(Date.now())
    }
  }, [])

  const onCreateModalSubmit = useCallback(async ({ ...data }: PayloadToCreateEditCMSPayload): Promise<void> => {
    const updatedData = await prepareData(data)
    if (updatedData) {
      await CMSService.createCMS(updatedData)
      setTableUpdatedAt(Date.now())
    }
  }, [])

  const fetchActiveCMSBanners = useCallback(async () => {
    const data = await CMSService.getActiveCMSBanners(editRow?.tenant_id, currentTab)

    if (data) {
      setActiveBanners(data)
    }
  }, [editRow, currentTab])

  useEffect(() => {
    fetchActiveCMSBanners()
  }, [tableUpdatedAt])

  const actions = useMemo(() => [{ name: t("edit"), handler: onEditRow }], [])
  const activeSteps = useMemo<ActiveStepType[]>(
    () =>
      activeBanners
        .filter((item) => item.type === enumCMSTypes.HOMEPAGE_BANNER)
        .map((item) => ({
          order: item.order,
          id: item.id
        })),
    [activeBanners]
  )

  const tabsForAdmin = Object.keys(enumCMSTypes)
  const tabsForOperators = tabsForAdmin.filter(
    (tab) => tab !== enumCMSTypes.TENANTS_CONFIG && tab !== enumCMSTypes.TENANT_COLORS && tab !== enumCMSTypes.TERMS_CONTENT
  )

  const tabs = user?.role === "ADMIN" ? tabsForAdmin : tabsForOperators

  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <a
            key={index}
            onClick={() => setCurrentTab(tab as enumCMSTypes)}
            className={clsx("tab tab-bordered", {
              "tab-active": currentTab === tab
            })}
          >
            {tab}
          </a>
        ))}
      </div>

      {currentTab === enumCMSTypes.TENANTS_CONFIG ? (
        <CMSTenantConfigEditor />
      ) : currentTab === enumCMSTypes.TENANT_COLORS ? (
        <CMSColorEditor />
      ) : currentTab === enumCMSTypes.TERMS_CONTENT ? (
        <CMSTextEditor />
      ) : (
        <>
          <div className="flex flex-col gap-4 sm:flex-row sm:items-end justify-between">
            <div className="max-w-sm flex flex-col gap-2 sm:flex-row sm:gap-8">
              <SearchInput title={t("search")} onChange={setSearchValue} />
              <DropdownMenu
                title={t("Tenant")}
                options={tenantOptions}
                value={tenant}
                onChange={(ev: ChangeEvent<HTMLSelectElement>) => setTenant(ev.target.value)}
              />
            </div>

            <button className="btn btn-primary" onClick={() => setOpenCreateModal(true)}>
              {t("create")}
            </button>
          </div>

          <Table
            title={t("banners")}
            handler={CMSService.getCMSTable}
            columns={tableCMSColumns}
            actions={actions}
            updatedAt={tableUpdatedAt}
            filters={{ q: searchValue, type: currentTab }}
            defaultSortOrder={SortOrderType.ASC}
            defaultSortOrderBy={SortOrderBy.ID}
            onRowClick={(row: CreateCMSPayload) => onEditRow(row)}
          />
        </>
      )}

      <ModalCMS
        open={openEditModal}
        data={editRow}
        handler={editRowHandler}
        onSubmit={onEditModalSubmit}
        type={currentTab}
        onDelete={onDelete}
        activeSteps={activeSteps}
      />
      <ModalCMS
        open={openCreateModal}
        handler={createPromoCodeHandler}
        onSubmit={onCreateModalSubmit}
        type={currentTab}
        activeSteps={activeSteps}
      />
    </div>
  )
}

export default CMSPage
