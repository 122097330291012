import React, { FC } from "react"

interface IProps {
  title: string
  checked: boolean
  onChange: any
  id?: string
  name?: string
}

const TitledCheckbox: FC<IProps> = ({ title, checked, onChange, id, name }) => {
  return (
    <div className="form-control">
      <label className="cursor-pointer label">
        <span className="label-text">{title}</span>
        <input id={id} type="checkbox" checked={checked} onChange={onChange} className="checkbox" name={name} />
      </label>
    </div>
  )
}

export default TitledCheckbox
