import useApi from "util/api"
import {
  Coin,
  EditUserBalancePayload,
  EditUserBalanceResponse,
  EditUserPayload,
  EditUserResponse,
  GetSingleUser,
  GetUserBetsResponse,
  GetUserCommentsResponse,
  GetUserReferralsResponse,
  GetUserRewardGamesResponse,
  GetUserTransactionsResponse,
  IUserService,
  Paginated,
  User,
  UserBonus,
  UsersTableResponse
} from "./type"
import { REQ } from "constants/api/requests"
import { IResponse, IResponsePayload } from "constants/interfaces"
import { formatRewardGameStatus } from "util/formatter"
import { toast } from "react-toastify"
import { Optional } from "types/common"

class UserService implements IUserService {
  public async getUser(): Promise<User | void> {
    try {
      const { data } = await useApi.get<IResponsePayload<User>>(REQ.USER.GET)
      if (data.success) {
        return data.response.payload
      }
    } catch (error) {}
  }

  public async editUser(id: string, payload: Partial<EditUserPayload>): Promise<EditUserResponse | void> {
    const { data } = await useApi.put<IResponse<EditUserResponse>>(REQ.ADMIN_DASHBOARD.USER.USER.replace(":id", id), payload)

    if (data?.success) {
      return data?.response
    }
  }

  public async editUserBalance(id: string, payload: Partial<EditUserBalancePayload>): Promise<EditUserBalanceResponse[] | void> {
    const { data } = await useApi.put<IResponse<EditUserBalanceResponse[]>>(
      REQ.ADMIN_DASHBOARD.USER.USER_BALANCE.replace(":id", id),
      payload
    )

    if (data?.success) {
      return data?.response
    }
  }

  public async swapUserBalance(id: string, payload: { coin: Coin }): Promise<EditUserBalanceResponse[] | void> {
    const { data } = await useApi.post<IResponse<EditUserBalanceResponse[]>>(
      REQ.ADMIN_DASHBOARD.USER.SWAP_USER_BALANCE.replace(":id", id),
      payload
    )

    if (data?.success) {
      return data?.response
    }
  }

  public async getUsersTable(
    page: number,
    pageSize: number,
    id: number | undefined,
    { q, createdAt }: { q: string; createdAt: (Date | null)[] },
    sortRow?: string,
    sortOrder?: string
  ): Promise<UsersTableResponse | void> {
    try {
      const params = {
        page,
        pageSize,
        q: q || null,
        createdAt: createdAt || null,
        sortRow,
        sortOrder
      }

      const { data } = await useApi.get<IResponse<UsersTableResponse>>(REQ.ADMIN_DASHBOARD.USERS, { params })

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? err?.data?.message ?? "Failed to get users")
    }
  }

  public async getAffiliatedUsersTable(
    page: number,
    pageSize: number,
    id: string,
    filter: { q: string; createdAt: (Date | null)[] } | undefined,
    sortRow?: string,
    sortOrder?: string
  ): Promise<UsersTableResponse | void> {
    try {
      const params = { page, pageSize, q: filter?.q || null, createdAt: filter?.createdAt || null, sortRow, sortOrder }
      const { data } = await useApi.get<IResponse<UsersTableResponse>>(REQ.ADMIN_DASHBOARD.AFFILIATED_USERS.replace(":userId", id), {
        params
      })

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? err?.data?.message ?? "Failed to get users")
    }
  }

  public async getUserById(id: string): Promise<GetSingleUser | void> {
    const { data } = await useApi.get<IResponse<GetSingleUser>>(REQ.ADMIN_DASHBOARD.USER.USER.replace(":id", id))

    if (data?.success) {
      return data?.response
    }
  }

  public async getUserBets(
    page: number,
    pageSize: number,
    id: number | undefined,
    { createdAt }: { createdAt?: (Date | null)[] },
    sortRow?: string,
    sortOrder?: string
  ): Promise<GetUserBetsResponse | void> {
    const params = {
      createdAt: createdAt || null,
      page,
      pageSize,
      sortRow,
      sortOrder
    }
    const { data } = await useApi.get<IResponse<GetUserBetsResponse>>(
      REQ.ADMIN_DASHBOARD.USER.USER_BETS.replace(":id", id ? id.toString() : ""),
      { params }
    )

    if (data?.success) {
      return data?.response
    }
  }

  public async getUserReferrals(
    page: number,
    pageSize: number,
    id: number | undefined,
    { createdAt }: { createdAt?: (Date | null)[] },
    sortRow?: string,
    sortOrder?: string
  ): Promise<GetUserReferralsResponse | void> {
    const params = {
      createdAt: createdAt || null,
      page,
      pageSize,
      sortRow,
      sortOrder
    }

    const { data } = await useApi.get<IResponse<GetUserReferralsResponse>>(
      REQ.ADMIN_DASHBOARD.USER.USER_REFERRALS.replace(":id", id ? id.toString() : ""),
      { params }
    )

    if (data?.success) {
      return data?.response
    }
  }

  public async getUserTransactions(
    page: number,
    pageSize: number,
    id: number | undefined,
    { type, status, createdAt }: { type: string; status: string; createdAt?: (Date | null)[] },
    sortRow?: string,
    sortOrder?: string
  ): Promise<GetUserTransactionsResponse | void> {
    const params = {
      page,
      pageSize,
      type: type || null,
      status: status || null,
      createdAt: createdAt || null,
      sortRow,
      sortOrder
    }

    const { data } = await useApi.get<IResponse<GetUserTransactionsResponse>>(
      REQ.ADMIN_DASHBOARD.USER.USER_TRANSACTIONS.replace(":id", id ? id.toString() : ""),
      { params }
    )

    if (data?.success) {
      return data?.response
    }
  }

  public async getUserRewardGames(
    page: number,
    pageSize: number,
    id: number | undefined,
    filters: any,
    sortRow?: string,
    sortOrder?: string
  ): Promise<GetUserRewardGamesResponse | void> {
    try {
      const params = {
        page,
        pageSize,
        sortRow,
        sortOrder
      }

      const { data } = await useApi.get<IResponse<GetUserRewardGamesResponse>>(
        REQ.ADMIN_DASHBOARD.USER.USER_REWARD_GAMES.replace(":id", id ? id.toString() : ""),
        { params }
      )

      if (data?.success) {
        data.response.data = data?.response?.data.map((rg: any) => ({ ...rg, status: formatRewardGameStatus(rg) }))
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to get user reward games")
    }
  }

  public async addUserRewardGames(id: string, gameIdentifier: string): Promise<any> {
    const { data } = await useApi.post(REQ.ADMIN_DASHBOARD.USER.USER_ADD_REWARD_GAME, { id, gameIdentifier })

    if (data?.success) {
      return data?.response
    }
  }

  public async deleteUserRewardGames(id: string, gameRewardId: string): Promise<any> {
    const { data } = await useApi.delete<IResponse<any>>(REQ.ADMIN_DASHBOARD.USER.USER_DELETE_REWARD_GAME, {
      data: { id: id, gameRewardId: gameRewardId }
    })

    if (data?.success) {
      return data?.response
    }
  }

  public async getUserBonuses(
    page: number,
    pageSize: number,
    id: string | undefined,
    filters: any,
    sortRow?: string,
    sortOrder?: string
  ): Promise<Optional<Paginated<UserBonus[]>>> {
    try {
      const { status, createdAt } = filters ?? {}
      const params = { page, pageSize, sortRow, sortOrder, status, createdAt }
      const { data } = await useApi.get<IResponse<GetUserRewardGamesResponse>>(
        REQ.ADMIN_DASHBOARD.USER.GET_BONUS.replace(":id", id?.toString() ?? ""),
        { params }
      )

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to get user bonuses")
    }
  }

  public async getUserTotalBonus(id: string): Promise<any> {
    try {
      const { data } = await useApi.get<IResponse<any>>(REQ.ADMIN_DASHBOARD.USER.GET_TOTAL_BONUS.replace(":id", id?.toString()))

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to get user bonus")
    }
  }

  public async getAcitivtyLogs(
    page: number,
    pageSize: number,
    id: string | undefined,
    filters: any,
    sortRow?: string,
    sortOrder?: string
  ): Promise<Optional<Paginated<UserBonus[]>>> {
    try {
      const { status, createdAt } = filters ?? {}
      const params = { page, pageSize, sortRow, sortOrder, status, createdAt }
      const { data } = await useApi.get<IResponse<any>>(REQ.ADMIN_DASHBOARD.USER.ACCOUNT_ACTIVITIES.replace(":id", id?.toString() ?? ""), {
        params
      })

      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed")
    }
  }

  public async cancelUserBonus(id: string, userId: string): Promise<any> {
    const { data } = await useApi.post<IResponse<any>>(REQ.ADMIN_DASHBOARD.USER.CANCEL_BONUS, {
      id,
      userId
    })

    if (data?.success) {
      return data?.response
    }
  }

  public async claimCustomBonus(payload: any): Promise<any> {
    const { data } = await useApi.post<IResponse<any>>(REQ.ADMIN_DASHBOARD.USER.CLAIM_CUSTOM_BONUS, payload)

    if (data?.success) {
      return data?.response
    }
  }

  public async addComment(userId: string, comment: string, category: string): Promise<any> {
    const { data } = await useApi.post<IResponse<any>>(REQ.ADMIN_DASHBOARD.USER.ADD_COMMENTS, {
      userId,
      comment,
      category
    })

    if (data?.success) {
      return data?.response
    }
  }

  public async getUserComments(
    page: number,
    pageSize: number,
    id: number | undefined,
    { category, createdAt }: { category: string; createdAt: (Date | null)[] },
    sortRow?: string,
    sortOrder?: string
  ): Promise<GetUserCommentsResponse | void> {
    const params = {
      page,
      pageSize,
      category: category || null,
      createdAt: createdAt || null,
      sortRow,
      sortOrder
    }

    const { data } = await useApi.get<IResponse<GetUserCommentsResponse>>(
      REQ.ADMIN_DASHBOARD.USER.USER_COMMENTS.replace(":id", id ? id.toString() : ""),
      { params }
    )

    if (data?.success) {
      return data?.response
    }
  }
}

export default new UserService()
