import { FC, ReactElement } from "react"
import InputField from "../../inputs/InputField"
import DropdownMenu from "../../inputs/DropdownMenu"
import { tenantOptions } from "../../../constants/bonus"
import TitledCheckbox from "../../inputs/TitledCheckbox"
import { enumCMSTypes } from "../../../constants/cms"
import { useFormikContext } from "formik"
import { FormValuesType } from "./ModalCMS"

type MainFieldsProps = {
  type: enumCMSTypes
}
export const MainFields: FC<MainFieldsProps> = ({ type }): ReactElement => {
  const { values, handleChange, touched, errors } = useFormikContext<FormValuesType>()
  return (
    <>
      <div className="w-full">
        <InputField
          id="name"
          title="Name"
          value={values.name}
          onChange={handleChange}
          invalid={!!(touched.name && errors.name)}
          altLabelTwo={touched.name ? errors.name : ""}
        />
      </div>
      <div className="w-full">
        <DropdownMenu
          id="tenant_id"
          title="Tenant"
          options={tenantOptions}
          value={values.tenant_id}
          onChange={handleChange}
          placeholder="Please choose an option"
          invalid={Boolean(touched.tenant_id && errors.tenant_id)}
          altLabelOne={touched.tenant_id ? errors.tenant_id : ""}
        />
      </div>
      <div className="flex justify-between my-4">
        <div className="w-2/5">
          <TitledCheckbox id="active" title="Active banner" checked={values.active} onChange={handleChange} />
        </div>
        {type === enumCMSTypes.HOMEPAGE_BANNER && (
          <div className="w-2/5">
            <TitledCheckbox id="jackpots" title="Show jackpots counts" checked={values.jackpots} onChange={handleChange} />
          </div>
        )}
      </div>
      {type === enumCMSTypes.PROMOTION_BANNER ? (
        <>
          <div className="w-full">
            <InputField
              id="headline"
              title="Headline"
              value={values.headline}
              onChange={handleChange}
              invalid={!!(touched.headline && errors.headline)}
              altLabelTwo={touched.headline ? errors.headline : ""}
            />
          </div>
          <div className="w-full">
            <InputField
              id="subheadline"
              title="Subheadline"
              value={values.subheadline}
              onChange={handleChange}
              invalid={!!(touched.subheadline && errors.subheadline)}
              altLabelTwo={touched.subheadline ? errors.subheadline : ""}
            />
          </div>
          <div className="w-full">
            <InputField
              id="link"
              title="Link"
              value={values.link}
              onChange={handleChange}
              invalid={!!(touched.link && errors.link)}
              altLabelTwo={touched.link ? errors.link : ""}
            />
          </div>
        </>
      ) : type === enumCMSTypes.HOMEPAGE_BANNER ? (
        <div className="w-full">
          <InputField
            id="order"
            title="Order"
            value={values.order}
            onChange={handleChange}
            invalid={!!(touched.order && errors.order)}
            altLabelTwo={touched.order ? errors.order : ""}
            type="number"
          />
        </div>
      ) : null}
    </>
  )
}
