import { FC } from "react"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

interface IProps {
  options: { name: string; value: string; disable?: boolean }[]
  id?: string
  title?: string
  value?: any
  onChange?: any
  invalid?: boolean
  altLabelOne?: string
  noAllValueText?: boolean
  bgColor?: string
  placeholder?: string
  disableEmptyOption?: boolean
  hideEmptyOption?: boolean
  disabled?: boolean
  name?: string
}

const DropdownMenu: FC<IProps> = (props) => {
  const { t } = useTranslation("landing", { useSuspense: false })
  const {
    options,
    id,
    value,
    onChange,
    invalid,
    altLabelOne,
    noAllValueText,
    disableEmptyOption = false,
    hideEmptyOption = false,
    title,
    placeholder = t("all"),
    disabled = false,
    name
  } = props

  return (
    <div className="w-full form-control">
      {Boolean(title) && (
        <label className="label">
          <span className={clsx("label-text", { "text-error": invalid })}>{title}</span>
        </label>
      )}
      <select
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        className={clsx("select select-bordered bg-transparent outline-none px-[5px] rounded-[10px]", { "select-error": invalid })}
        disabled={disabled}
      >
        <option key={0} value="" {...(disableEmptyOption && { disabled: true })} className={clsx({ hidden: hideEmptyOption })}>
          {!noAllValueText && placeholder}
        </option>
        {options.map(({ name, value, disable = false }) => (
          <option key={value} value={value} disabled={disable}>
            {name}
          </option>
        ))}
      </select>
      <label>
        <span className={clsx("label-text-alt", { "text-error": invalid })}>{altLabelOne}</span>
      </label>
    </div>
  )
}

export default DropdownMenu
