export enum TableDataType {
  NUMBER,
  FIAT_CURRENCY,
  TFIAT_CURRENCY,
  CRYPTO_CURRENCY,
  STRING,
  BOOLEAN,
  DATE,
  DATES,
  JSON,
  PERCENTAGE,
  IMAGE,
  FILE,
  DATE_PICKER,
  SHORTENED_STRING
}

export type TrustedStatusType = "UNTRUSTED" | "TRUSTED" | "INVALID" | "NOT_STARTED"

export enum SortOrderBy {
  ID = "id",
  CREATED_AT = "created_at"
}

export enum SortOrderType {
  ASC = "asc",
  DESC = "desc"
}

export enum GameStatusType {
  Play = "PLAY",
  Claim = "CLAIM",
  Claimed = "CLAIMED",
  Pending = "PENDING"
}

export enum LanguageTypesEnum {
  USA_English = "en", // default
  UK_English = "gb",
  Canadian_English = "ca",
  Swedish = "se",
  German = "de",
  Norwegian = "no",
  Finnish = "fi",
  Spanish = "es",
  Portuguese = "pt",
  Turkish = "tr",
  Polish = "pl",
  Hungarian = "hu",
  French = "fr",
  Russian = "ru",
  Philippines = "tl",
  Japanese = "jp",
  Vietnamese = "vn",
  Danish = "dk"
}

export enum DeviceViewType {
  Desktop = "desktop",
  Mobile = "mobile"
}
