import { FC, ReactElement, useCallback } from "react"
import { useFormikContext } from "formik"
import { TenantColorsData } from "../../../types/api"
import ToolTip from "../components/ToolTip"
import InputColor from "../../inputs/InputColor"

const ModalsColorFields: FC = (): ReactElement => {
  const { values, setFieldValue } = useFormikContext<TenantColorsData>()
  const updateValue = useCallback((name: string, value: string) => {
    setFieldValue(name, value)
  }, [])
  return (
    <div className="my-6">
      <div className="my-2 relative group">
        <ToolTip text="This is the background for containers on the pages" />
        <InputColor
          name="modals.main"
          value={values.modals.main}
          onChange={(value: string) => updateValue("modals.main", value)}
          title="Modal background color"
        />
      </div>
      <div className="my-2 relative group">
        <ToolTip text="This is the background for inner blocks in containers on the pages" />
        <InputColor
          name="modals.inner"
          value={values.modals.inner}
          onChange={(value: string) => updateValue("modals.inner", value)}
          title="Modal inner color"
        />
      </div>
      <div className="my-2 relative group">
        <ToolTip text="This is the background for modal windows" />
        <InputColor
          name="modals.dialog"
          value={values.modals.dialog}
          onChange={(value: string) => updateValue("modals.dialog", value)}
          title="Dialog background color"
        />
      </div>
      <div className="my-2 relative group">
        <ToolTip text="This is the background for dropdown lists" />
        <InputColor
          name="modals.dropdown"
          value={values.modals.dropdown}
          onChange={(value: string) => updateValue("modals.dropdown", value)}
          title="Dropdown background color"
        />
      </div>
    </div>
  )
}

export default ModalsColorFields
