import React, { FC, ReactElement } from "react"
import { GameSessions } from "../../../services/user/type"

type FormatTableGameSessionsProps = {
  rawValue?: GameSessions[]
  currency?: string
}
const FormatTableGameSessions: FC<FormatTableGameSessionsProps> = ({ rawValue, currency }): ReactElement => {
  if (!Array.isArray(rawValue) || !rawValue.length || rawValue.some((item: any) => !item || !item.currency)) {
    return <ul></ul>
  }
  if (currency) {
    const gameSessionForCurrency = rawValue?.find((item: any) => item.currency === currency)
    return <ul>{gameSessionForCurrency?.short_id}</ul>
  }
  return <ul>{rawValue && rawValue.map((item: any, index: number) => <li key={index}>{`${item.currency} - ${item.short_id}`}</li>)}</ul>
}

export default FormatTableGameSessions
