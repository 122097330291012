import { FC, ReactElement } from "react"
import TitledCheckbox from "../../inputs/TitledCheckbox"
import DropdownMenu from "../../inputs/DropdownMenu"
import { tenantOptions } from "../../../constants/bonus"
import { enumPromoTypes, promoTypes } from "../../../constants/promo-codes"
import { FormValuesType } from "./ModalPromoCode"
import { useFormikContext } from "formik"

const typeRelevants = ["usd_amount", "bonus_id", "bonus_amount", "wager_requirements", "days_to_expire"]

const MainFields: FC = (): ReactElement => {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<FormValuesType>()
  const handleChangePromoCodeType = (event: any) => {
    const typeValue = event.target.value
    handleChangePromoVariables(typeValue)
  }
  const handleChangePromoVariables = (type: string) => {
    setFieldValue("type", type)
    typeRelevants.forEach((trItem) => {
      if (type === enumPromoTypes.CASH_BALANCE && trItem === "usd_amount") {
        setFieldValue(trItem, 0)
      } else if (type === enumPromoTypes.DEPOSIT_BONUS && trItem === "bonus_id") {
        setFieldValue(trItem, "")
      } else if (
        type === enumPromoTypes.NO_DEPOSIT_BONUS &&
        (trItem === "bonus_amount" || trItem === "wager_requirements" || trItem === "days_to_expire")
      ) {
        setFieldValue(trItem, 0)
      } else {
        setFieldValue(trItem, undefined)
      }
      if (type === enumPromoTypes.NO_DEPOSIT_BONUS) {
        setFieldValue("kyc", true)
      } else {
        setFieldValue("kyc", false)
      }
    })
  }
  return (
    <>
      <TitledCheckbox id="active" name="active" title="Active" checked={values.active} onChange={handleChange} />

      <DropdownMenu
        id="tenant_id"
        name="tenant_id"
        title="Tenant"
        options={tenantOptions}
        value={values.tenant_id}
        onChange={handleChange}
        placeholder="Please choose an option"
        invalid={Boolean(touched.tenant_id && errors.tenant_id)}
        altLabelOne={touched.tenant_id ? errors.tenant_id : ""}
      />

      <DropdownMenu
        id="type"
        name="type"
        title="Promo Code Type"
        options={promoTypes}
        value={values.type}
        onChange={handleChangePromoCodeType}
        placeholder="Please choose an option"
        invalid={Boolean(touched.type && errors.type)}
        altLabelOne={touched.type ? errors.type : ""}
      />
    </>
  )
}

export default MainFields
