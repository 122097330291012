import { CSVLink } from "react-csv"
import TableColumnsFilter from "../TableColumnsFilter"
import TableDropdown from "../TableDropdown"
import { tablePageSize } from "../../../constants/data"
import React, { FC, ReactElement } from "react"
import { TableColumnsInterface } from "../../../constants/interfaces"
import { useTranslation } from "react-i18next"

type TableAccordionTopFiltersProps = {
  title: string
  buttonHandler?: any
  buttonText?: string
  isExport?: boolean
  isColumnsFilterable?: boolean
  rowsLength: number
  loading: boolean
  handleExportAll: () => void
  loadingCsvData: boolean
  csvData: any[]
  selectedColumns: TableColumnsInterface[]
  setSelectColumns: React.Dispatch<React.SetStateAction<TableColumnsInterface[]>>
  pageSize: number
  onPageSizeChange(value: number): void
  csvLinkEl: React.MutableRefObject<any>
}
const TableAccordionTopFilters: FC<TableAccordionTopFiltersProps> = ({
  title,
  buttonHandler,
  buttonText,
  isExport = false,
  isColumnsFilterable = false,
  rowsLength,
  loading,
  loadingCsvData,
  handleExportAll,
  csvData,
  selectedColumns,
  setSelectColumns,
  pageSize,
  onPageSizeChange,
  csvLinkEl
}): ReactElement => {
  const { t } = useTranslation(["landing"], { useSuspense: false })
  return (
    <div className="flex items-center justify-between py-4">
      <div className="flex items-center justify-start text-lg text-black dark:text-white gap-4">
        {title}
        {loading ? <span className="loading loading-spinner loading-md text-gray-400" /> : null}
        {buttonHandler ? (
          <button className="btn btn-primary btn-sm ml-8" onClick={buttonHandler}>
            {buttonText}
          </button>
        ) : null}
      </div>
      {rowsLength ? (
        <div className="flex items-center gap-4">
          {isExport && (
            <>
              <button className="btn btn-success btn-link px-0" onClick={handleExportAll} disabled={loadingCsvData}>
                {loadingCsvData ? t("loading_csv") : t("export_csv")}
              </button>
              <CSVLink data={csvData} ref={csvLinkEl} />
            </>
          )}
          {isColumnsFilterable && <TableColumnsFilter columns={selectedColumns} setColumns={setSelectColumns} />}
          <div className="flex items-center gap-2">
            <label>Rows per page:</label>
            <TableDropdown value={pageSize} onValueChange={onPageSizeChange} columns={tablePageSize} />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default TableAccordionTopFilters
