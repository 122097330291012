import SingleOptTimeRangePicker from "components/date/SingleOptTimePeriodPicker"
import SearchInput from "components/search/SearchInput"
import Table from "components/tables/Table"
import { URL } from "constants/api/requests"
import { tableUsersColumns } from "constants/data"
import { useProps } from "contexts/PropsContext"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { adminsAccess } from "services/user/type"
import userService from "services/user/user.service"

const Users: FC = () => {
  const { user } = useProps()
  const { t } = useTranslation(["landing"], { useSuspense: false })

  const [query, setQuery] = useState<string>("")
  const [tableUpdatedAt] = useState<Date | number>(Date.now())
  const [timeframe, setTimeframe] = useState<(Date | null)[]>([])

  const handleChangeTimeFrame = (dates: (Date | null)[]) => setTimeframe(dates)

  const onUserClick = (row: any): void => {
    if (row?.id) {
      // if (user.role === "SUPPORT") navigate(URL.USER.replace(":id", row.id))
      window.open(URL.USER.replace(":id", row.id), "_blank")
    }
  }

  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      {user && (
        <div className="flex items-center justify-center text-xl text-black dark:text-white">
          {t("welcome", { value: user?.display_name })}
        </div>
      )}
      <div className="flex gap-4">
        <div className="max-w-sm">
          <SearchInput title="Username/Email" onChange={setQuery} />
        </div>

        <div className="max-w-md">
          <SingleOptTimeRangePicker onTimeframeChange={handleChangeTimeFrame} />
        </div>
      </div>
      <Table
        title={t("users")}
        handler={userService.getUsersTable}
        columns={tableUsersColumns}
        filters={{ q: query, createdAt: timeframe }}
        onRowClick={onUserClick}
        updatedAt={tableUpdatedAt}
        isExport={adminsAccess.includes(user.role)}
        isColumnsFilterable
      />
    </div>
  )
}

export default Users
