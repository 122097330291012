import React, { FC, ReactElement, useCallback, useMemo, useState } from "react"
import { Form, Formik } from "formik"
import { enumEnCurrencyFlag, Tenant, TenantConfigData } from "../../../types/api"
import SealsFields from "./SealsFields"
import ContactsFields from "./ContactsFields"
import FilesFields from "./FilesFields"
import DropdownMenu from "../../inputs/DropdownMenu"
import { cloneDeep } from "lodash"
import { CMSService } from "../../../services/cms/cms.service"
import { prepareTenantSetupData } from "../../../util/api"
import { formTenantConfigData } from "../../../util/editorsForms"
import RegularFields from "../CMSColorEditor/RegularFields"

const enLangFlagsOptions = Object.entries(enumEnCurrencyFlag).map((item) => ({ value: item[1], name: item[0] }))
type Props = {
  tenant: Tenant
}
const EditorForm: FC<Props> = ({ tenant }): ReactElement => {
  const [loading, setLoading] = useState<"loading" | false>(false)
  const onSubmit = useCallback(
    async (values: TenantConfigData) => {
      setLoading("loading")
      const updatedTenant: Tenant = cloneDeep(tenant)
      const updatedData = await prepareTenantSetupData(values)
      if (updatedData) {
        updatedTenant.setup_data = updatedData
        await CMSService.updateTenantData(tenant.id, updatedTenant)
      }
      setLoading(false)
    },
    [tenant]
  )
  const initialValues = useMemo(() => formTenantConfigData(tenant), [tenant])

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values }) => (
        <Form onSubmit={handleSubmit}>
          <FilesFields />
          <RegularFields />
          <SealsFields />
          <ContactsFields />
          <div className="mt-2">
            <DropdownMenu
              id="enCurrencyFlag"
              title="English language flag"
              value={values.enCurrencyFlag}
              options={enLangFlagsOptions}
              onChange={handleChange}
              noAllValueText
            />
          </div>
          <button className={`btn btn-primary mt-8 ${loading || ""}`} type="submit" disabled={!!loading}>
            Save
          </button>
        </Form>
      )}
    </Formik>
  )
}

export default EditorForm
