import { FC, ReactElement } from "react"
import InputField from "../../inputs/InputField"
import { useFormikContext } from "formik"
import { FormValuesType } from "./ModalPromoCode"

const CacheBalance: FC = (): ReactElement => {
  const { values, touched, errors, handleChange } = useFormikContext<FormValuesType>()
  return (
    <InputField
      id="usd_amount"
      name="usd_amount"
      title="Amount"
      altLabelOne={`$${(values.usd_amount || 0) / 100}`}
      type="number"
      value={values.usd_amount ?? 0}
      onChange={handleChange}
      invalid={!!(touched.usd_amount && errors.usd_amount)}
      altLabelTwo={touched.usd_amount ? errors.usd_amount : ""}
    />
  )
}

export default CacheBalance
