import { FC, ReactElement } from "react"
import { useFormikContext } from "formik"
import { TenantConfigData } from "../../../types/api"
import InputField from "../../inputs/InputField"

const ContactsFields: FC = (): ReactElement => {
  const { handleChange, values } = useFormikContext<TenantConfigData>()
  return (
    <div className="mt-6">
      <h3>Contacts</h3>
      <div className="mt-4">
        <h5>Community</h5>
        <InputField name="contacts.community.twitter" value={values.contacts.community.twitter} onChange={handleChange} title="Twitter" />
        <InputField name="contacts.community.discord" value={values.contacts.community.discord} onChange={handleChange} title="Discord" />
        <InputField
          name="contacts.community.telegram"
          value={values.contacts.community.telegram}
          onChange={handleChange}
          title="Telegram"
        />
        <InputField
          name="contacts.community.instagram"
          value={values.contacts.community.instagram}
          onChange={handleChange}
          title="Instagram"
        />
      </div>
      <div className="mt-4">
        <h5>Contact</h5>
        <InputField name="contacts.contact.support" value={values.contacts.contact.support} onChange={handleChange} title="Support" />
        <InputField
          name="contacts.contact.complaints"
          value={values.contacts.contact.complaints}
          onChange={handleChange}
          title="Complaints"
        />
        <InputField name="contacts.contact.LiveChat" value={values.contacts.contact.LiveChat} onChange={handleChange} title="Live Chat" />
      </div>
    </div>
  )
}

export default ContactsFields
