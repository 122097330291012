import React, { FC, ReactElement, useMemo } from "react"
import UploadPreview from "../../preview/UploadPreview"
import { useFormikContext } from "formik"
import { TenantConfigData } from "../../../types/api"
import TitledCheckbox from "../../inputs/TitledCheckbox"

const FilesFields: FC = (): ReactElement => {
  const { values, setFieldValue, handleChange } = useFormikContext<TenantConfigData>()
  const isDesktopImageJson = useMemo(() => {
    let isDesktopImageJson = false
    if (typeof values.appDesktopLogo === "string") {
      isDesktopImageJson = values.appDesktopLogo.slice(values.appDesktopLogo.lastIndexOf(".") + 1) === "json"
    }
    if (values.appDesktopLogo instanceof File) {
      isDesktopImageJson = values.appDesktopLogo.name.slice(values.appDesktopLogo.name.lastIndexOf(".") + 1) === "json"
    }
    return isDesktopImageJson
  }, [values.appDesktopLogo])
  return (
    <div className="flex flex-col gap-3 mb-4">
      <UploadPreview
        id="appDesktopLogo"
        value={values.appDesktopLogo}
        title="Desktop Logo image (can be with animation in JSON format)"
        setFieldValue={setFieldValue}
        onlyName={isDesktopImageJson}
      />
      <div className="flex items-end gap-4">
        <div className="w-[50%]">
          <UploadPreview id="appMobileLogo" value={values.appMobileLogo} title="Mobile logo image" setFieldValue={setFieldValue} />
        </div>
        <div className="w-[50%]">
          <TitledCheckbox
            id="mobileLogoToUp"
            title="Mobile logo to up (above other elements, when it is wide)"
            checked={values.mobileLogoToUp}
            onChange={handleChange}
          />
        </div>
      </div>
      <UploadPreview id="spinner" value={values.spinner} title="Spinner image" setFieldValue={setFieldValue} />
      <UploadPreview id="Age18Icon" value={values.Age18Icon} title="Age+ image" setFieldValue={setFieldValue} />
    </div>
  )
}

export default FilesFields
