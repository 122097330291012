import { FC, useCallback, useMemo } from "react"
import { Formik, Form, FormikHelpers } from "formik" // eslint-disable-line
import * as Yup from "yup"
import { enumPromoTypes } from "constants/promo-codes"
import InputField from "../../inputs/InputField"
import Modal from "../Modal"
import { enumTenant } from "constants/bonus"
import NoDepositBonus from "./NoDepositBonus"
import DepositFreeSpins from "./DepositFreeSpins"
import CacheBalance from "./CacheBalance"
import MainFields from "./MainFields"
import TitledCheckbox from "../../inputs/TitledCheckbox"

export interface FormValuesType {
  id: string
  code: string
  type: string
  active: boolean
  allowed_uses: number
  tenant_id: string
  bonus_id?: string
  bonus_amount?: number
  usd_amount?: number
  wager_requirements?: number
  days_to_expire?: number
  kyc: boolean
}

interface IProps {
  data?: any
  open: boolean
  handler: any
  onSubmit: (values: FormValuesType) => void
  promoBonuses: any
  promoBonusesSpins: any
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required(),
  type: Yup.string().required("This field is required"),
  tenant_id: Yup.string().required("This field is required"),
  allowed_uses: Yup.number().integer().min(0, "The value must not be a negative number"),
  bonus_id: Yup.string().when("type", {
    is: enumPromoTypes.DEPOSIT_BONUS,
    then: Yup.string().required("Please choose one")
  }),
  usd_amount: Yup.number().when("type", {
    is: enumPromoTypes.CASH_BALANCE,
    then: Yup.number().required().min(1)
  }),
  bonus_amount: Yup.number().when("type", {
    is: enumPromoTypes.NO_DEPOSIT_BONUS,
    then: Yup.number().required().min(1)
  }),
  wager_requirements: Yup.number().when("type", {
    is: enumPromoTypes.NO_DEPOSIT_BONUS,
    then: Yup.number().required().min(1)
  }),
  days_to_expire: Yup.number().when("type", {
    is: enumPromoTypes.NO_DEPOSIT_BONUS,
    then: Yup.number().required().min(1)
  })
})

const defaultInitialValues: FormValuesType = {
  id: "",
  code: "",
  type: enumPromoTypes.CASH_BALANCE,
  active: false,
  allowed_uses: 0,
  tenant_id: enumTenant.Slotwhales,
  kyc: false
}

const ModalPromoCode: FC<IProps> = ({ data, open, handler, onSubmit, promoBonuses, promoBonusesSpins }) => {
  const initialValues = useMemo(() => {
    let initValues: FormValuesType = defaultInitialValues
    if (data) {
      initValues = {
        ...data,
        bonus_id: data.type === enumPromoTypes.DEPOSIT_BONUS ? data.bonus_id ?? "" : undefined,
        usd_amount: data.type === enumPromoTypes.CASH_BALANCE ? data.usd_amount ?? 0 : undefined,
        bonus_amount: data.type === enumPromoTypes.NO_DEPOSIT_BONUS ? data.bonus_amount ?? 0 : undefined,
        wager_requirements: data.type === enumPromoTypes.NO_DEPOSIT_BONUS ? data.wager_requirements ?? 0 : undefined,
        days_to_expire: data.type === enumPromoTypes.NO_DEPOSIT_BONUS ? data.days_to_expire ?? 0 : undefined
      }
    }
    return initValues
  }, [data])

  const onSubmitHandler = useCallback((values: FormValuesType, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setTouched({})
    onSubmit({ ...values })
    handler()
  }, [])

  return (
    <Modal open={open} handler={handler}>
      <div className="relative flex flex-col justify-between modal-box">
        <Formik<FormValuesType> initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={validationSchema}>
          {({ handleChange, values, touched, errors, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="flex flex-row space-x-4 mt-3">
                <div className="w-full">
                  <InputField
                    id="code"
                    name="code"
                    title="Promo Code"
                    value={values.code}
                    onChange={handleChange}
                    invalid={!!(touched.code && errors.code)}
                    altLabelTwo={touched.code ? errors.code : ""}
                  />
                </div>
                <div className="w-full">
                  <InputField
                    id="allowed_uses"
                    name="allowed_uses"
                    title="Allowed Uses"
                    type="number"
                    value={values.allowed_uses}
                    onChange={handleChange}
                    invalid={!!(touched.allowed_uses && errors.allowed_uses)}
                    altLabelTwo={touched.allowed_uses ? errors.allowed_uses : ""}
                  />
                </div>
              </div>
              <div className="flex flex-row space-x-4 mt-3">
                <div className="w-full">
                  <MainFields />
                  {values.type === enumPromoTypes.CASH_BALANCE && <CacheBalance />}
                  {(values.type === enumPromoTypes.DEPOSIT_BONUS || values.type === enumPromoTypes.FREESPINS) && (
                    <DepositFreeSpins data={data} promoBonuses={promoBonuses} promoBonusesSpins={promoBonusesSpins} />
                  )}
                  {values.type === enumPromoTypes.NO_DEPOSIT_BONUS && <NoDepositBonus />}
                  {(values.type === enumPromoTypes.NO_DEPOSIT_BONUS || values.type === enumPromoTypes.CASH_BALANCE) && (
                    <div className="my-5">
                      <TitledCheckbox
                        title="KYC required"
                        checked={values.kyc ?? false}
                        onChange={handleChange}
                        name="kyc"
                        id="kyc"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-action">
                <button className="btn btn-secondary" onClick={handler} type="button">
                  Cancel
                </button>
                <button className="btn btn-primary" type="submit">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default ModalPromoCode
