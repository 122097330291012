import { Maybe } from "types/common"

export type Comments = {
  id: string
  user_id: string
  commentor: string
  comment: string
  category: string
  created_at: string
}

export enum BonusType {
  DEPOSIT = "DEPOSIT",
  CUSTOM = "CUSTOM",
  PROMO = "PROMO",
  FREESPINS = "FREESPINS"
}

export type Bonus = {
  id: string
  type: BonusType
  title: string
  bonus_percent: number
  max_usage_per_user: number
  max_bonus_amount: number
  active: boolean
  days_to_expire: number
  wager_requirements: number
  min_deposit: Maybe<number>
  zero_out_threshold: Maybe<number>
  bet_max_limit: Maybe<number>
  withdraw_max_limit: Maybe<number>
  created_at: Date
  updated_at: Date
}

export enum CardGateway {
  SP = "SP",
  FS = "FS",
  FV0 = "FV0",
  GB1 = "GB1",
  GB2 = "GB2",
  FV1 = "FV1",
  FV2 = "FV2"
}

export type TenantColorsData = {
  primary: string
  secondary: string
  success: string
  header: string
  gradient: {
    main: string[]
    titleDividersGradient: string[]
    walletButtonGradient: string[]
    textGradient: string[]
  }
  modals: {
    main: string
    inner: string
    dialog: string
    dropdown: string
  }
  walletHoverBg: string
  walletBoxShadow: string
  viewMoreColor: string
  accordionBorderColor: string
  iconLinesColor: string
  internalNavDotDividerColor: string
  internalNavWrappingBorderColor: string
  cardBorder: string
  contentButtonTextColor: string
  outlinedButtonTextColor: string
}

export enum enumEnCurrencyFlag {
  US = "us",
  GB = "gb"
}

export type TenantContactsType = {
  community: {
    twitter: string
    discord: string
    telegram: string
    instagram: string
  }
  contact: {
    support: string
    complaints: string
    LiveChat: string
  }
}
export type TenantConfigData = {
  appMobileLogo: string | File
  spinner: string | File
  Age18Icon: string | File
  mobileLogoToUp: boolean
  appDesktopLogo: string | File
  sealANJText: string
  noSealText: string
  seals: string[]
  contacts: TenantContactsType
  enCurrencyFlag: enumEnCurrencyFlag
  isDisableFiat: boolean
  isDisableCrypto: boolean
}

export type Tenant = {
  id: string
  color_theme: TenantColorsData
  prod_frontend: string
  stage_frontend: string
  setup_data: TenantConfigData
}
