import { useCallback, useEffect, useState } from "react"
import { Tenant } from "../types/api"
import { CMSService } from "../services/cms/cms.service"

export type UseTenantsDataReturn = { tenants: Tenant[]; toggleEditForm: (val: string) => void; tenantId: string | null }
export const useTenantsData = (): UseTenantsDataReturn => {
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [tenantId, setTenantId] = useState<string | null>(null)
  useEffect(() => {
    getTenants()
  }, [])
  const getTenants = useCallback(async () => {
    try {
      const data = await CMSService.getTenants()
      if (data) {
        setTenants(data)
      }
    } catch (err) {
      console.error(`Failed to fetch tenants: ${err}`)
    }
  }, [])
  const toggleEditForm = useCallback(
    (value: string) => {
      if (tenantId === value) {
        setTenantId(null)
      } else {
        setTenantId(value)
      }
    },
    [tenantId]
  )

  return { tenants, toggleEditForm, tenantId }
}
