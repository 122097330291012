import { FC, ReactElement } from "react"

type Props = {
  text: string
}
const ToolTip: FC<Props> = ({ text }): ReactElement => (
  <div className="hidden group-hover:inline-block absolute z-10 px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-xs tooltip dark:bg-gray-700 right-0 animate-opacity">
    {text}
  </div>
)

export default ToolTip
