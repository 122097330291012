import { TableDataType } from "./eums"
import { TableColumnsInterface } from "./interfaces"

export enum enumCMSTypes {
  HOMEPAGE_BANNER = "HOMEPAGE_BANNER",
  BONUS_BANNER = "BONUS_BANNER",
  PROMOTION_BANNER = "PROMOTION_BANNER",
  TERMS_CONTENT = "TERMS_CONTENT",
  TENANT_COLORS = "TENANT_COLORS",
  TENANTS_CONFIG = "TENANTS_CONFIG"
}

export const tableCMSColumns: TableColumnsInterface[] = [
  {
    value: "id",
    title: "Id",
    format: TableDataType.NUMBER,
    sorting: true
  },
  {
    value: "tenant_id",
    title: "Tenant",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "name",
    title: "Name",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "type",
    title: "Type",
    format: TableDataType.STRING,
    sorting: true
  },
  {
    value: "banner_image",
    title: "Desktop banner",
    format: TableDataType.IMAGE,
    sorting: false
  },
  {
    value: "banner_image_mobile",
    title: "Mobile banner",
    format: TableDataType.IMAGE,
    sorting: false
  },
  {
    value: "active",
    title: "Activated",
    format: TableDataType.BOOLEAN,
    sorting: true
  }
]
