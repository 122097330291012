import { IReferrals, ReferralsTableResponse } from "./type"
import useApi from "../../util/api"
import { IResponse } from "../../constants/interfaces"
import { REQ } from "../../constants/api/requests"
import { toast } from "react-toastify"

class ReferralsService implements IReferrals {
  public async getReferrersTable(
    page: number,
    pageSize: number,
    id: number | undefined,
    filters: { q: string; createdAt: (Date | null)[] } | undefined,
    sortRow?: string,
    sortOrder?: string
  ): Promise<ReferralsTableResponse | void> {
    try {
      const params = { page, pageSize, sortRow, sortOrder }
      const { data } = await useApi.get<IResponse<ReferralsTableResponse>>(REQ.ADMIN_DASHBOARD.REFERRALS.LIST, { params })
      if (data?.success) {
        return data?.response
      }
    } catch (err: any) {
      toast.error(err?.message ?? err?.data?.message ?? "Failed to fetch referrals")
    }
  }
}

export default new ReferralsService()
