import React, { FC, ReactElement } from "react"
import { TableDataType } from "../../../constants/eums"
import { formatDateTime, formatTableCell } from "../../../util/formatter"
import { Link } from "react-router-dom"
import FormatTableGameSessions from "./FormatTableGameSessions"
import { FaCalendarAlt } from "react-icons/fa"
import MultiDatesPicker from "../../date/MultiDatesPicker"
import { TableColumnsInterface } from "../../../constants/interfaces"
import { GameSessions } from "../../../services/user/type"

type TableAccordionColumnProps = {
  column: TableColumnsInterface
  rawValue: any
  currency?: string
  onDatePickerClick: () => void
  setActiveDatePicker: () => void
  handleDateChange?: (dates: string[]) => void
  activeDatePicker: boolean
  dateData: any
  gameSessions?: GameSessions[]
  style?: Record<string, any>
}
const TableAccordionColumn: FC<TableAccordionColumnProps> = ({
  column,
  rawValue,
  currency,
  onDatePickerClick,
  setActiveDatePicker,
  handleDateChange,
  activeDatePicker,
  dateData,
  gameSessions,
  style
}): ReactElement => {
  return (
    <div className="flex justify-start text-sm py-3 px-4" style={style}>
      {column.customRender ? (
        <div className="justify-self-center">{column.customRender(rawValue)}</div>
      ) : column.format === TableDataType.IMAGE && rawValue ? (
        <img className="h-10 w-auto max-w-full" src={rawValue} alt="Image icon" />
      ) : column.format === TableDataType.FILE ? (
        <div>{rawValue}</div>
      ) : column.format === TableDataType.DATE ? (
        <div className="tooltip" data-tip={formatDateTime(rawValue)}>
          {formatTableCell(rawValue, column.format)}
        </div>
      ) : column.link ? (
        <Link to={column.link(rawValue)} className="underline">
          {formatTableCell(rawValue, column.format)}
        </Link>
      ) : column.format === TableDataType.DATES ? (
        <div className="flex flex-col gap-1">
          {rawValue &&
            rawValue.split(",").map((item: any, index: number) => (
              <button className="w-28 btn btn-sm btn-secondary px-2 py-1" key={index} onClick={(e) => e.stopPropagation()}>
                {item}
              </button>
            ))}
        </div>
      ) : column.format === TableDataType.JSON && column.value === "gameSessions" ? (
        <FormatTableGameSessions rawValue={rawValue} currency={currency} />
      ) : column.format === TableDataType.DATE_PICKER ? (
        <div className="relative">
          <button
            className="btn btn-circle btn-outline btn-sm"
            onClick={(e) => {
              e.stopPropagation() // prevent row click
              onDatePickerClick()
              setActiveDatePicker()
            }}
          >
            <FaCalendarAlt />
          </button>
          {activeDatePicker && (
            <div
              className="absolute z-50 mt-2 -translate-x-1/3 bg-white shadow-md p-2 rounded-md w-32"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <MultiDatesPicker
                id="schedule"
                title="Schedule"
                onChange={(field: string, dates: string[]) => handleDateChange?.(dates)}
                data={dateData}
              />
            </div>
          )}
        </div>
      ) : column.value === "gameSessionId" ? (
        <FormatTableGameSessions rawValue={gameSessions} currency={currency} />
      ) : (
        formatTableCell(rawValue, column.format)
      )}
    </div>
  )
}

export default TableAccordionColumn
