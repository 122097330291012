import { FC, ReactElement } from "react"
import { FieldArray, FormikProps } from "formik" // eslint-disable-line
import { TenantColorsData } from "../../../types/api"
import ToolTip from "../components/ToolTip"
import InputColor from "../../inputs/InputColor"

const TextGradientFields: FC = (): ReactElement => (
  <FieldArray
    name="gradient.textGradient"
    render={({ form }) => (
      <div className="mt-10 relative group">
        <ToolTip text="These are the text gradient colors, for example for the 'Deposit now' text" />
        <h4>Gradient colors: texts</h4>
        {(form as FormikProps<TenantColorsData>).values.gradient.textGradient.map((_, index) => (
          <div key={index} className="flex items-end">
            <span className="mr-3">{index + 1}</span>
            <InputColor
              name={`gradient.textGradient.${index}`}
              value={(form as FormikProps<TenantColorsData>).values.gradient.textGradient[index]}
              onChange={(value: string) => form.setFieldValue(`gradient.textGradient.${index}`, value)}
            />
          </div>
        ))}
      </div>
    )}
  />
)

export default TextGradientFields
