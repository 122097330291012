import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import userService from "services/user/user.service"
import ModalEditBalance from "../components/modals/ModalEditBalance"
import ModalFreeSpinGame from "../components/modals/ModalFreeSpinGame"
import ModalConfirmAction from "../components/modals/ModalConfirmAction"
import { Coin, GetSingleUser } from "services/user/type"
import { CommentsCard, UserGeneralCard, UserWagerCard, UserRakeBackCard, UserBalanceCard } from "features/user/cards"
import { UserTabContents } from "features/user/tab-contents"
import { Maybe } from "types/common"
import { AddUserBonus } from "features/user/actions/AddUserBonus"
import { EditUserProfile } from "features/user/actions/EditUserProfile"
import { Simulate } from "react-dom/test-utils"

const User: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(["landing"], { useSuspense: false })
  const [updateKey, forceUpdate] = useReducer((x) => x + 1, 0)
  const [editRow, setEditRow] = useState<any>(null)
  const [editBalance, setEditBalance] = useState<any>(null)
  const [tableUpdatedAt, setTableUpdatedAt] = useState<number>(Date.now())
  const [userResult, setUserResult] = useState<Maybe<GetSingleUser>>(null)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const onLoad = useCallback(async () => {
    try {
      if (!id) return
      setLoading(true)
      const result = await userService.getUserById(id)
      if (result) {
        setUserResult(result)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    onLoad()
  }, [editBalance])

  const onRewardGameDelete = useCallback(
    async (rewardGame: any): Promise<void> => {
      if (!userResult) return
      const response = await userService.deleteUserRewardGames(userResult.id, rewardGame.id)
      if (response) {
        // @ts-ignore
        toast.success(t("success_delete_reward_game"))
        setTableUpdatedAt(Date.now())
      }
    },
    [userResult]
  )

  const createFreeSpinGameHandler = useCallback((): void => {
    setOpenCreateModal(false)
    setTableUpdatedAt(Date.now())
  }, [])

  const onAddRewardGames = useCallback(
    async ({ game }: any): Promise<void> => {
      if (!userResult) return
      const response = await userService.addUserRewardGames(userResult.id, game)
      if (response) {
        // @ts-ignore
        toast.success(t("success_added_reward_game"))
      }
    },
    [userResult]
  )

  const editRowHandler = useCallback((): void => {
    setEditRow(null)
    setTableUpdatedAt(Date.now())
  }, [])

  const editBalanceHandler = useCallback(() => {
    setEditBalance(null)
  }, [])

  const handleAddBonusSuccess = useCallback(() => {
    onLoad()
    forceUpdate()
  }, [])

  const content = userResult ? (
    <div className="w-full grid grid-col-1 gap-8 px-8">
      <div className="flex items-center justify-center text-xl text-black dark:text-white">{userResult.display_name}</div>

      <div className="flex space-x-2">
        <EditUserProfile user={userResult} onSuccess={onLoad} />
        <button className="btn btn-secondary" onClick={() => setEditBalance(userResult)}>
          Edit Balance
        </button>
        <AddUserBonus
          userId={userResult.id}
          bonusBalance={userResult.coins[Coin.B_USD]}
          onSuccess={handleAddBonusSuccess}
          key={`add-user-bonus-${updateKey}`}
        />
      </div>

      <div className="flex flex-row space-x-4">
        <UserGeneralCard user={userResult} />
        <UserWagerCard user={userResult} />
        <UserRakeBackCard user={userResult} />
        <UserBalanceCard user={userResult} reloadUser={onLoad} />
      </div>

      <CommentsCard user={userResult} onSuccess={onLoad} />

      <UserTabContents
        forceUpdate={forceUpdate}
        tableUpdatedAt={tableUpdatedAt}
        updateKey={updateKey}
        userResult={userResult}
        onLoad={onLoad}
        setEditRow={setEditRow}
        setOpenCreateModal={setOpenCreateModal}
        setTableUpdatedAt={setTableUpdatedAt}
        setUserResult={setUserResult}
        gameSessions={userResult.gameSessions}
      />
    </div>
  ) : (
    <div className="flex items-center justify-center text-black dark:text-white">{t(t("user_not_exist"))}</div>
  )

  return (
    <div className="w-full p-8">
      {loading ? (
        <div className="grid place-items-center h-screen">
          <span className="loading loading-spinner loading-lg" />
        </div>
      ) : (
        content
      )}

      <ModalFreeSpinGame open={openCreateModal} handler={createFreeSpinGameHandler} onSubmit={onAddRewardGames} />
      <ModalConfirmAction
        data={editRow}
        handler={editRowHandler}
        text={t("modal_conformation_action", {
          actionText: t("delete"),
          title: editRow?.title,
          id: editRow?.id
        })}
        actionText={t("delete")}
        actionHandler={onRewardGameDelete}
      />
      <ModalEditBalance data={editBalance} handler={editBalanceHandler} />
    </div>
  )
}

export default User
