import { enumEnCurrencyFlag, Tenant, TenantColorsData, TenantConfigData } from "../types/api"

export const formTenantConfigData = (tenant: Tenant): TenantConfigData => ({
  // files
  appMobileLogo: tenant.setup_data.appMobileLogo ?? "",
  spinner: tenant.setup_data.spinner ?? "",
  mobileLogoToUp: tenant.setup_data.mobileLogoToUp ?? false,
  Age18Icon: tenant.setup_data.Age18Icon ?? "",
  appDesktopLogo: tenant.setup_data.appDesktopLogo ?? "",

  // fields
  sealANJText: tenant.setup_data.sealANJText ?? "",
  noSealText: tenant.setup_data.noSealText ?? "",
  seals: tenant.setup_data.seals ?? [""],
  contacts: {
    community: {
      twitter: tenant.setup_data.contacts?.community?.twitter ?? "",
      discord: tenant.setup_data.contacts?.community?.discord ?? "",
      telegram: tenant.setup_data.contacts?.community?.telegram ?? "",
      instagram: tenant.setup_data.contacts?.community?.instagram ?? ""
    },
    contact: {
      support: tenant.setup_data.contacts?.contact?.support ?? "",
      complaints: tenant.setup_data.contacts?.contact?.complaints ?? "",
      LiveChat: tenant.setup_data.contacts?.contact?.LiveChat ?? ""
    }
  },
  enCurrencyFlag: tenant.setup_data.enCurrencyFlag ?? enumEnCurrencyFlag.US,
  isDisableFiat: tenant.setup_data.isDisableFiat ?? false,
  isDisableCrypto: tenant.setup_data.isDisableCrypto ?? false
})

export const formTenantColorsData = (tenant: Tenant): TenantColorsData => ({
  primary: tenant.color_theme.primary,
  secondary: tenant.color_theme.secondary,
  success: tenant.color_theme.success,
  header: tenant.color_theme.header,
  gradient: {
    main: tenant.color_theme.gradient.main,
    titleDividersGradient: tenant.color_theme.gradient.titleDividersGradient,
    walletButtonGradient: tenant.color_theme.gradient.walletButtonGradient,
    textGradient: tenant.color_theme.gradient.textGradient
  },
  modals: {
    main: tenant.color_theme.modals.main,
    inner: tenant.color_theme.modals.inner,
    dialog: tenant.color_theme.modals.dialog,
    dropdown: tenant.color_theme.modals.dropdown
  },
  walletHoverBg: tenant.color_theme.walletHoverBg,
  walletBoxShadow: tenant.color_theme.walletBoxShadow,
  viewMoreColor: tenant.color_theme.viewMoreColor,
  accordionBorderColor: tenant.color_theme.accordionBorderColor,
  iconLinesColor: tenant.color_theme.iconLinesColor,
  internalNavDotDividerColor: tenant.color_theme.internalNavDotDividerColor,
  internalNavWrappingBorderColor: tenant.color_theme.internalNavWrappingBorderColor,
  cardBorder: tenant.color_theme.cardBorder,
  contentButtonTextColor: tenant.color_theme.contentButtonTextColor,
  outlinedButtonTextColor: tenant.color_theme.outlinedButtonTextColor
})
