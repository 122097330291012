import { FC, ReactElement, useCallback } from "react"
import InputField from "../../inputs/InputField"
import { useFormikContext } from "formik"
import { TenantColorsData } from "../../../types/api"
import ToolTip from "../components/ToolTip"
import InputColor from "../../inputs/InputColor"

type FormControlProps = {
  name: string
  title?: string
  tooltip?: string
}
const FormControl: FC<FormControlProps> = ({ name, title, tooltip }): ReactElement => {
  const { values, setFieldValue } = useFormikContext<TenantColorsData>()
  const updatedValue = useCallback(
    (value: string) => {
      setFieldValue(name, value)
    },
    [name]
  )
  return (
    <div className="my-2 relative group">
      {tooltip && <ToolTip text={tooltip} />}
      {/*/ @ts-ignore*/}
      <InputColor name={name} value={values[name]} onChange={updatedValue} title={title} />
    </div>
  )
}

export default FormControl
