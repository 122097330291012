import { useProps } from "contexts/PropsContext"
import { useFormik } from "formik"
import { useIsMounted } from "hooks/useIsMounted"
import { toast } from "react-toastify"
import { editBalanceAccess } from "services/user/type"
import userService from "services/user/user.service"
import { shallowChanged } from "util/shallowChanged"
import * as Yup from "yup"

import InputField from "../inputs/InputField"
import Modal from "./Modal"

Yup.setLocale({
  mixed: {
    required: "Required Field"
  },
  number: {
    min: "Must be greater than or equal to ${min}"
  }
})

interface FormValuesType {
  USD: number
  B_USD: number
}

interface IProps {
  data: any
  handler: () => void
}

const ModalEditBalance = (props: IProps) => {
  const { data, handler } = props
  const isMounted = useIsMounted()
  const { user } = useProps()

  const validationSchema = Yup.object().shape({
    USD: editBalanceAccess.includes(user.role) ? Yup.number().required().min(0) : Yup.number().required().max(0),
    B_USD: editBalanceAccess.includes(user.role) ? Yup.number().required().min(0) : Yup.number().required().max(0)
  })

  const formik = useFormik<FormValuesType>({
    initialValues: {
      USD: data?.coins?.USD ?? 0,
      B_USD: data?.coins?.B_USD ?? 0
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }): Promise<void> => {
      try {
        if (!formik.dirty) return
        // const changedValues = (Object.keys(values) as Array<keyof FormValuesType>).reduce<Partial<FormValuesType>>((result, key) => {
        //   if (values[key] === formik.initialValues[key]) return result
        //   result[key] = values[key]
        //   return result
        // }, {})
        const changedValues = shallowChanged(values, formik.initialValues)

        await userService.editUserBalance(data.id, changedValues)
        if (!isMounted()) return
        handler()
        resetForm()
      } catch (err: any) {
        toast.error(err?.data?.message ?? err?.message ?? "Failed")
      }
    },
    validationSchema
  })

  return (
    <Modal open={!!data} handler={handler}>
      <div className="relative flex flex-col justify-between modal-box">
        <div className="flex flex-col">
          <div className="flex items-center space-x-4">
            <InputField
              id="USD"
              type="number"
              title="USD"
              value={formik.values.USD}
              onChange={formik.handleChange}
              altLabelOne={`$${formik.values.USD / 100}`}
              invalid={!!(formik.errors.USD && formik.touched.USD)}
              altLabelTwo={formik.touched.USD ? formik.errors.USD : ""}
            />
            <InputField
              id="B_USD"
              type="number"
              title="Bonus"
              value={formik.values.B_USD}
              onChange={formik.handleChange}
              altLabelOne={`$${formik.values.B_USD / 100}`}
              invalid={!!(formik.errors.B_USD && formik.touched.B_USD)}
              altLabelTwo={formik.touched.B_USD ? formik.errors.B_USD : ""}
            />
          </div>
          <div className="modal-action flex items-center space-x-4">
            <button className="btn btn-secondary flex-auto" onClick={handler}>
              Cancel
            </button>
            <button className="btn btn-primary flex-auto" onClick={formik.submitForm}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalEditBalance
