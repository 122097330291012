import DropdownMenu from "components/inputs/DropdownMenu"
import InputField from "components/inputs/InputField"
import TitledCheckbox from "components/inputs/TitledCheckbox"
import Modal from "components/modals/Modal"
import { countryInfoList, Genders, roles } from "constants/data"
import { GatewayOptions } from "constants/deposit-gateway"
import { useProps } from "contexts/PropsContext"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { EditUserPayload, editUserRoleAccess, GetSingleUser, rakebackAccess, UserRole } from "services/user/type"
import userService from "services/user/user.service"
import { CardGateway } from "types/api"
import { shallowChanged } from "util/shallowChanged"
import * as Yup from "yup"

interface FormValuesType {
  id: string
  username: string
  first_name: string
  last_name: string
  email_address: string
  phone_number: string
  dob: string
  gender: string
  country: string
  city: string
  address: string
  address2: string
  zip: string
  // activated: boolean
  email_verified: boolean
  kyc: boolean
  role: UserRole
  level: number
  rakeback_balance: number
  affiliate_balance: number
  influencer_percent: number | null
  affiliate_percent: number
  next_card_gateway: CardGateway
  exclusion_period?: string
}

const exclusionPeriods = [
  { name: "", value: "0" },
  { name: "1 Day", value: "1" },
  { name: "7 Days", value: "7" },
  { name: "14 Days", value: "14" },
  { name: "30 Days", value: "30" },
  { name: "90 Days", value: "90" },
  { name: "180 Days", value: "180" },
  { name: "365 Days", value: "365" },
  { name: "1825 Days", value: "1825" },
  { name: "Permanent", value: "permanent" }
]

const validationSchema = Yup.object().shape({
  level: Yup.number().min(0, "Min level is 0").max(100, "Max level is 100").required(),
  influencer_percent: Yup.number().min(0, "Must be positive or 0").optional().nullable(),
  affiliate_percent: Yup.number().min(0, "Must be positive or 0").optional(),
  first_name: Yup.string().matches(/^[a-z\s]+$/i, "Only English letters"),
  last_name: Yup.string().matches(/^[a-z\s]+$/i, "Only English letters"),
  phone_number: Yup.string(),
  country: Yup.string().required(),
  exclusion_period: Yup.string().nullable()
})

type ModalEditUserType = {
  open: boolean
  handler: () => void
  user: GetSingleUser
  onSuccess: () => void
}
export const ModalEditUser = ({ open, handler, user, onSuccess }: ModalEditUserType) => {
  const { user: appUser } = useProps()
  const formik = useFormik<FormValuesType>({
    initialValues: {
      id: user.id,
      username: user.username,
      first_name: user.personal_profile.first_name ?? "",
      last_name: user.personal_profile.last_name ?? "",
      email_address: user.email_address,
      phone_number: user.phone_number ?? "",
      dob: user.personal_profile.dob ?? "",
      gender: user.personal_profile.gender ?? "",
      country: user.personal_profile.country ?? "",
      city: user.personal_profile.city ?? "",
      address: user.personal_profile.address ?? "",
      address2: user.personal_profile.address2 ?? "",
      zip: user.personal_profile.zip ?? "",
      // activated: user.activated,
      email_verified: user.email_verified,
      kyc: user.kyc,
      role: user.role,
      level: user.level,
      rakeback_balance: user.rakeback_balance,
      affiliate_balance: user.affiliate_balance || 0,
      influencer_percent: user.influencer_percent || 50,
      affiliate_percent: user.affiliate_percent || 0,
      next_card_gateway: user.next_card_gateway ?? CardGateway.FV0,
      exclusion_period: ""
    },
    enableReinitialize: true,
    onSubmit: async ({ id, ...values }: EditUserPayload & { id: string }) => {
      try {
        if (!formik.dirty) return
        const changedValues = shallowChanged(values, formik.initialValues)
        if (changedValues.first_name || changedValues.last_name) changedValues.display_name = values.first_name + " " + values.last_name
        await userService.editUser(id, changedValues)
        toast.success("Successfully updated user")
        handler()
        onSuccess()
      } catch (error: any) {
        toast.error(error?.data?.message ?? error?.message ?? "Failed update")
      }
    },
    validationSchema
  })

  return (
    <Modal open={open} handler={handler}>
      <div className="flex flex-col modal-box w-full max-w-full">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <InputField id="username" title="Username" value={formik.values.username} disabled />
          <InputField id="email_address" title="Email" value={formik.values.email_address} onChange={formik.handleChange} />
          <InputField
            name="first_name"
            title="First Name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            invalid={!!(formik.errors.first_name && formik.touched.first_name)}
            {...(formik.errors.first_name && formik.touched.first_name && { altLabelTwo: formik.errors.first_name })}
          />
          <InputField
            name="last_name"
            title="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            invalid={!!(formik.errors.last_name && formik.touched.last_name)}
            {...(formik.errors.last_name && formik.touched.last_name && { altLabelTwo: formik.errors.last_name })}
          />
          <DropdownMenu
            id="next_card_gateway"
            title="Deposit Gateway"
            placeholder="Select a Gateway"
            disableEmptyOption
            options={GatewayOptions}
            value={formik.values.next_card_gateway}
            onChange={formik.handleChange}
          />
          <InputField
            id="phone_number"
            title="Phone number"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            invalid={!!(formik.errors.phone_number && formik.touched.phone_number)}
            {...(formik.errors.phone_number && formik.touched.phone_number && { altLabelTwo: formik.errors.phone_number })}
          />
          <div>
            <label className="label">
              <span className="label-text">Role</span>
            </label>
            <DropdownMenu
              id="role"
              options={roles.map((role) => ({
                ...role,
                disable: role.name === UserRole.ADMIN && appUser.role !== UserRole.ADMIN
              }))}
              value={formik.values.role}
              onChange={formik.handleChange}
              bgColor="transparent"
              disabled={!editUserRoleAccess.includes(appUser.role)}
            />
          </div>
          <DropdownMenu
            id="gender"
            title="Gender"
            options={Genders}
            value={formik.values.gender}
            onChange={formik.handleChange}
            bgColor="transparent"
            placeholder="Please choose an option"
          />
          <InputField name="dob" type="date" title="Birthday" value={formik.values.dob} onChange={formik.handleChange} />
          <div className="lg:max-w-[200px]">
            <DropdownMenu
              id="country"
              title="Country"
              options={countryInfoList}
              value={formik.values.country}
              onChange={formik.handleChange}
              bgColor="transparent"
              invalid={!!(formik.errors.country && formik.touched.country)}
              {...(formik.errors.country && formik.touched.country && { altLabelOne: formik.errors.country })}
              placeholder="Please choose an option"
            />
          </div>
          <InputField id="city" title="City" value={formik.values.city} onChange={formik.handleChange} />
          <InputField id="address" title="Street1" value={formik.values.address} onChange={formik.handleChange} />
          <InputField id="address2" title="Street2" value={formik.values.address2} onChange={formik.handleChange} />
          <InputField name="zip" title="Zipcode" value={formik.values.zip} onChange={formik.handleChange} />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-10">
          {rakebackAccess.includes(appUser.role) ? (
            <>
              <InputField
                id="rakeback_balance"
                title="Rakeback balance"
                type="number"
                value={formik.values.rakeback_balance}
                onChange={formik.handleChange}
                altLabelOne={`$${formik.values.rakeback_balance / 100}`}
                invalid={!!(formik.errors.rakeback_balance && formik.touched.rakeback_balance)}
                altLabelTwo={formik.touched.rakeback_balance ? formik.errors.rakeback_balance : ""}
              />
              <InputField
                id="level"
                title="VIP level"
                type="number"
                value={formik.values.level}
                onChange={formik.handleChange}
                invalid={!!(formik.errors.level && formik.touched.level)}
                altLabelTwo={formik.touched.level && formik.touched.level ? formik.errors.level : ""}
              />
              <InputField
                id="affiliate_percent"
                title="Affiliate Percent"
                type="number"
                value={formik.values.affiliate_percent}
                onChange={formik.handleChange}
                invalid={!!(formik.errors.affiliate_percent && formik.touched.affiliate_percent)}
                altLabelTwo={formik.touched.affiliate_percent && formik.touched.affiliate_percent ? formik.errors.affiliate_percent : ""}
              />
              <InputField
                id="influencer_percent"
                title="Affiliate boost"
                type="number"
                value={formik.values.influencer_percent}
                onChange={formik.handleChange}
                invalid={!!(formik.errors.influencer_percent && formik.touched.influencer_percent)}
                altLabelTwo={formik.touched.influencer_percent && formik.touched.influencer_percent ? formik.errors.influencer_percent : ""}
              />
              <InputField
                id="affiliate_balance"
                title="Affiliate balance"
                type="number"
                value={formik.values.affiliate_balance}
                onChange={formik.handleChange}
                altLabelOne={`$${formik.values.affiliate_balance / 100}`}
                invalid={!!(formik.errors.affiliate_balance && formik.touched.affiliate_balance)}
                altLabelTwo={formik.touched.affiliate_balance ? formik.errors.affiliate_balance : ""}
              />
            </>
          ) : null}
          <div className="flex flex-col items-end gap-4">
            <DropdownMenu
              id="exclusion_period"
              title={`Exclusion Period (${
                !user.activated
                  ? "Permanently Excluded"
                  : user.exclusion_end
                  ? `Excluded Until ${new Date(user.exclusion_end).toLocaleDateString()}`
                  : "Not Excluded"
              })`}
              options={exclusionPeriods}
              placeholder=""
              hideEmptyOption
              value={formik.values.exclusion_period || ""}
              onChange={(event: any) => {
                formik.setFieldValue("exclusion_period", event.target.value)
              }}
            />
            <button
              type="button"
              className="btn btn-warning w-full"
              onClick={() => {
                formik.setFieldValue("exclusion_period", "0")
              }}
            >
              Remove Exclusion
            </button>
          </div>
          <TitledCheckbox id="kyc" title="KYC" checked={formik.values.kyc} onChange={formik.handleChange} />
          <TitledCheckbox
            id="email_verified"
            title="Email verified"
            checked={formik.values.email_verified}
            onChange={formik.handleChange}
          />
          {/* <TitledCheckbox id="activated" title="Activated" checked={formik.values.activated} onChange={formik.handleChange} /> */}
        </div>

        <div className="modal-action">
          <button className="btn btn-secondary" onClick={handler}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={formik.submitForm} disabled={!formik.dirty || formik.isSubmitting}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}
