import { FC, ReactElement, useMemo } from "react"
import TrashIcon from "assets/icons/trash-icon.svg"

interface IProps {
  id?: string
  value?: string | File
  setFieldValue?: any
  onlyName?: boolean
}

const ImagePreview: FC<IProps> = ({ id, value, setFieldValue, onlyName }) => {
  const imagePreviewContent = useMemo(() => {
    let imagePreviewContent: string | ReactElement = "No Image"
    if (value) {
      const src = typeof value === "string" ? value : URL.createObjectURL(value)
      const altValue = typeof value === "string" ? value : value.name
      const fileName = typeof value === "string" ? value.slice(value.lastIndexOf("/") + 1) : value.name
      imagePreviewContent = (
        <>
          {onlyName ? <div className="px-1">{fileName}</div> : <img className="max-w-full max-h-full rounded" src={src} alt={altValue} />}
          <div
            className="w-5 h-5 absolute -top-2 -right-2 rounded-full flex justify-center items-center bg-[#f3f3f330] cursor-pointer"
            onClick={() => setFieldValue(id, null)}
          >
            <img className="w-3 h-3" src={TrashIcon} alt="Trash icon" />
          </div>
        </>
      )
    }
    return imagePreviewContent
  }, [value, onlyName, id])

  return (
    <div className="w-full h-[84px] rounded flex justify-center items-center border border-gray-700 relative">{imagePreviewContent}</div>
  )
}

export default ImagePreview
