import React, { FC, ReactElement } from "react"
import UploadPreview from "../../preview/UploadPreview"
import { enumCMSTypes } from "../../../constants/cms"
import TitledCheckbox from "../../inputs/TitledCheckbox"
import { useFormikContext } from "formik"
import { FormValuesType } from "./ModalCMS"

type Props = {
  type: enumCMSTypes
}
const DefaultBanners: FC<Props> = ({ type }): ReactElement => {
  const { values, setFieldValue, handleChange, touched, errors } = useFormikContext<FormValuesType>()
  const isInvalid = touched.banner_image && !!errors.banner_image
  const altLabel = isInvalid ? errors.banner_image : ""
  return (
    <div>
      <h6 className="abel-text">Default banner images</h6>
      <div className="sm:flex gap-8">
        <div>
          <UploadPreview
            id="banner_image"
            value={values.banner_image}
            isInvalid={isInvalid}
            altLabelOne={altLabel}
            title="Desktop banner"
            setFieldValue={setFieldValue}
          />
          {type === enumCMSTypes.HOMEPAGE_BANNER && (
            <div>
              <UploadPreview
                id="banner_image_animation"
                value={values.banner_image_animation}
                title="Desktop banner animation"
                setFieldValue={setFieldValue}
                onlyName
                altLabelTwo="Upload the Lottie JSON file here"
                accept=".json"
              />
              <TitledCheckbox
                id="is_banner_image_animation"
                title={values.is_banner_image_animation ? "Turn off the animation" : "Turn on the animation"}
                checked={values.is_banner_image_animation}
                onChange={handleChange}
              />
            </div>
          )}
        </div>

        <div>
          <UploadPreview id="banner_image_mobile" value={values.banner_image_mobile} title="Mobile banner" setFieldValue={setFieldValue} />
          {type === enumCMSTypes.HOMEPAGE_BANNER && (
            <div>
              <UploadPreview
                id="banner_image_mobile_animation"
                value={values.banner_image_mobile_animation}
                title="Mobile banner animation"
                setFieldValue={setFieldValue}
                onlyName
                altLabelTwo="Upload the Lottie JSON file here"
                accept=".json"
              />
              <TitledCheckbox
                id="is_banner_image_mobile_animation"
                title={values.is_banner_image_mobile_animation ? "Turn off the animation" : "Turn on the animation"}
                checked={values.is_banner_image_mobile_animation}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DefaultBanners
