import clsx from "clsx"
import DropdownMenu from "components/inputs/DropdownMenu"
import { ModalBonus } from "components/modals/ModalBonus"
import SearchInput from "components/search/SearchInput"
import Table from "components/tables/Table"
import { tenantOptions } from "constants/bonus"
import { tableBonusesColumns, tableFSBonusesColumns } from "constants/data"
import { useProps } from "contexts/PropsContext"
import { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { BonusService } from "services/bonus/bonus.service"
import { createBonusAccess } from "services/user/type"
import { BonusType } from "types/api"

export const Bonueses = () => {
  const { user } = useProps()
  const { t } = useTranslation(["landing"], { useSuspense: false })

  const [editRow, setEditRow] = useState(null)
  const [editRowDate, setEditRowDate] = useState(null)
  const [query, setQuery] = useState<string>("")
  const [bonusTenant, setBonusTenant] = useState<string>("")
  const [open, setOpen] = useState(false)
  const [tableUpdatedAt, setTableUpdatedAt] = useState<Date | number>(Date.now())
  const [currentTab, setCurrentTab] = useState<BonusType>(BonusType.DEPOSIT)

  const closeHandler = (): void => {
    setOpen(false)
    setEditRow(null)
    setTableUpdatedAt(Date.now())
  }

  const openHandler = (row: any) => {
    setEditRow(row)
    setOpen(true)
  }

  const handleCreate = () => {
    setEditRow(null)
    setOpen(true)
  }
  const actions = [
    {
      name: "Edit",
      handler: (row: any) => openHandler(row)
    }
  ]

  const handleDateChange = async (rowId: string, dates: string[]) => {
    try {
      const formattedDates = dates.join(",")
      await BonusService.editBonus(rowId, { schedule: formattedDates })
      toast.success("Schedule updated successfully!")
    } catch (error) {
      toast.error("Failed to update schedule!")
    }
  }

  return (
    <div className="grid w-full gap-8 p-8 grid-col-1">
      {user && (
        <div className="flex items-center justify-center text-xl text-black dark:text-white">
          {t("welcome", { value: user?.display_name })}
        </div>
      )}
      <div className="tabs">
        {Object.keys(BonusType)
          .filter((item) => item !== BonusType.CUSTOM)
          .map((tab, index) => (
            <a
              key={index}
              onClick={() => setCurrentTab(tab as BonusType)}
              className={clsx("tab tab-bordered", {
                "tab-active": currentTab === tab
              })}
            >
              {tab}
            </a>
          ))}
      </div>
      <div className="flex items-end">
        <div className="flex flex-1 space-x-4 max-w-md">
          <SearchInput title="Search by title" onChange={setQuery} />
          <DropdownMenu
            title="Tenant"
            options={tenantOptions}
            value={bonusTenant}
            onChange={(ev: ChangeEvent<HTMLSelectElement>) => setBonusTenant(ev.target.value)}
          />
        </div>
        {createBonusAccess.includes(user.role) ? (
          <button className="btn btn-primary ml-auto" onClick={handleCreate}>
            {t("create")}
          </button>
        ) : null}
      </div>

      <Table
        title="Bonuses"
        handler={BonusService.getBonusesList}
        columns={currentTab === BonusType.FREESPINS ? tableFSBonusesColumns : tableBonusesColumns}
        filters={{ q: query, tenant: bonusTenant, type: currentTab }}
        onRowClick={(row: any) => openHandler(row)}
        actions={actions}
        updatedAt={tableUpdatedAt}
        onDatePickerClick={(row: any) => setEditRowDate(row)}
        dateData={editRowDate}
        handleDateChange={handleDateChange}
      />

      <ModalBonus data={editRow} handler={closeHandler} open={open} />
    </div>
  )
}
