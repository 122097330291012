import React, { FC, ReactElement } from "react"
import { FieldArray } from "formik"
import DropdownMenu from "../../inputs/DropdownMenu"
import { LanguageTypesEnum } from "../../../constants/eums"
import TrashIcon from "../../../assets/icons/trash-icon.svg"
import { BannerLanguage } from "../../../services/cms/type"
import { getValidationLangBannerResults } from "./helpers"
import { enumCMSTypes } from "../../../constants/cms"
import UploadPreview from "../../preview/UploadPreview"
import TitledCheckbox from "../../inputs/TitledCheckbox"

export const defaultBannerLang: BannerLanguage = {
  lang: "",
  desktop: "",
  desktop_animation: "",
  is_desktop_animation: false,
  mobile: "",
  mobile_animation: "",
  is_mobile_animation: false,
  jackpots: false
}
const allLanguages = Object.entries(LanguageTypesEnum)
  .map(([key, value]) => ({ name: key, value }))
  .filter((item) => item.value !== LanguageTypesEnum.USA_English)

type Props = {
  type: enumCMSTypes
}
export const ExtraBanners: FC<Props> = ({ type }): ReactElement => (
  <div className="w-full mt-5">
    <h3>Add banner per language (banners uploaded in the top are in English)</h3>
    <FieldArray
      name="banners_languages"
      render={({ form, push, remove }) => {
        const { setFieldValue, handleChange, errors, touched, values } = form
        return (
          <div>
            {values.banners_languages.map((banner: BannerLanguage, i: number) => {
              const { langErrorMessage, isLangInvalid } = getValidationLangBannerResults(form, i)
              // @ts-ignore
              const isTouched = touched.banners_languages && touched.banners_languages[i]?.desktop
              // @ts-ignore
              const isError = errors.banners_languages && errors.banners_languages[i]?.desktop
              const isInvalid = isTouched && !!isError
              // @ts-ignore
              const altLabel = isInvalid ? isError : ""
              return (
                <div className="border-y border-current py-3 flex flex-col" key={i}>
                  <DropdownMenu
                    id={`banners_languages.${i}.lang`}
                    value={banner.lang}
                    options={allLanguages}
                    onChange={handleChange}
                    invalid={isLangInvalid}
                    altLabelOne={isLangInvalid ? langErrorMessage : undefined}
                    noAllValueText
                  />

                  <div className="sm:flex gap-8">
                    <div>
                      <UploadPreview
                        id={`banners_languages[${i}].desktop`}
                        value={values.banners_languages[i].desktop}
                        isInvalid={isInvalid}
                        altLabelOne={altLabel}
                        title="Desktop banner"
                        setFieldValue={setFieldValue}
                      />
                      {type === enumCMSTypes.HOMEPAGE_BANNER && (
                        <div>
                          <UploadPreview
                            id={`banners_languages[${i}].desktop_animation`}
                            value={values.banners_languages[i].desktop_animation}
                            title="Desktop banner animation"
                            setFieldValue={setFieldValue}
                            onlyName
                            altLabelTwo="Upload the Lottie JSON file here"
                            accept=".json"
                          />
                          <TitledCheckbox
                            id={`banners_languages[${i}].is_desktop_animation`}
                            title={values.banners_languages[i].is_desktop_animation ? "Turn off the animation" : "Turn on the animation"}
                            checked={values.banners_languages[i].is_desktop_animation}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                    </div>

                    <div>
                      <UploadPreview
                        id={`banners_languages[${i}].mobile`}
                        value={values.banners_languages[i].mobile}
                        title="Mobile banner"
                        setFieldValue={setFieldValue}
                      />
                      {type === enumCMSTypes.HOMEPAGE_BANNER && (
                        <div>
                          <UploadPreview
                            id={`banners_languages[${i}].mobile_animation`}
                            value={values.banners_languages[i].mobile_animation}
                            title="Mobile banner animation"
                            setFieldValue={setFieldValue}
                            onlyName
                            altLabelTwo="Upload the Lottie JSON file here"
                          />
                          <TitledCheckbox
                            id={`banners_languages[${i}].is_mobile_animation`}
                            title={values.banners_languages[i].is_mobile_animation ? "Turn off the animation" : "Turn on the animation"}
                            checked={values.banners_languages[i].is_mobile_animation}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <button className="ml-auto mt-6" onClick={() => remove(i)} type="button">
                    <img className="w-5 h-5" src={TrashIcon} alt="Trash icon" />
                  </button>
                </div>
              )
            })}
            <button className="btn btn-accent mt-4" onClick={() => push({ ...defaultBannerLang })} type="button">
              Add banner for other language
            </button>
          </div>
        )
      }}
    />
  </div>
)
