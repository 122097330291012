import React, { FC, ReactElement } from "react"
import { TableColumnsInterface } from "../../../constants/interfaces"
import { SortOrderType } from "../../../constants/eums"
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti"

type TableAccordionHeaderProps = {
  selectedColumns: TableColumnsInterface[]
  handleRequestSort(value: string): void
  orderBy: string
  order: string
}
const TableAccordionHeader: FC<TableAccordionHeaderProps> = ({ selectedColumns, handleRequestSort, orderBy, order }): ReactElement => {
  return (
    <div className="flex bg-[#1e2530]">
      {selectedColumns
        .filter((column) => column.selected === true)
        .map((column: TableColumnsInterface, key: number) => (
          <button
            key={key}
            disabled={!column.sorting}
            className={`${column.sorting && "cursor-pointer"} flex justify-start items-center gap-1 uppercase py-3 px-4 text-[#a2adbb]`}
            onClick={() => handleRequestSort(column.value)}
            style={{ width: `${100 / selectedColumns.length}%` }}
          >
            {orderBy === column.value && (order === SortOrderType.ASC ? <TiArrowSortedUp /> : <TiArrowSortedDown />)}
            {column.title}
          </button>
        ))}
    </div>
  )
}

export default TableAccordionHeader
