import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import totalDataService from "services/total/total.service"
import { StatsResponse } from "services/total/types"

export const useStats = (timeframe: (Date | null)[], tenant: string) => {
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState<StatsResponse | undefined>()

  useEffect(() => {
    const getUserCountValue = async () => {
      try {
        setLoading(true)
        const res = await totalDataService.getStats(timeframe)
        if (res) setStats(res)
      } catch (err: any) {
        if (err.status === 409) return

        toast.error("Get Stats Failed")
      } finally {
        setLoading(false)
      }
    }

    if (timeframe.filter(Boolean).length !== 0) {
      getUserCountValue()
    }
  }, [timeframe, tenant])

  return { loading, data: stats }
}
