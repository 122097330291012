import React, { FC, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { adminsAccess } from "services/user/type"

import Login from "../components/cards/Login"
import { URL } from "../constants/api/requests"
import { useProps } from "../contexts/PropsContext"

const Home: FC = () => {
  const navigate = useNavigate()
  const { authenticated, user } = useProps()

  useEffect(() => {
    if (authenticated) {
      adminsAccess.includes(user.role) ? navigate(URL.DASHBOARD) : navigate(URL.USERS)
    }
  }, [authenticated])

  return (
    <div className="w-full h-1/2 p-8 flex items-center justify-center">
      <Login />
    </div>
  )
}

export default Home
