import axios from "axios"
import { Cookies } from "react-cookie"
import { toast } from "react-toastify"
import { BannerLanguage, CreateCMSPayload } from "../services/cms/type"
import { LanguageTypesEnum } from "../constants/eums"
import { CMSService } from "../services/cms/cms.service"
import { v4 as uuid } from "uuid"
import { PayloadToCreateEditCMSPayload } from "../views/CMSPage"
import { cloneDeep } from "lodash"
import { TenantConfigData } from "../types/api"
// import authService from "services/auth/auth.service"

export const cookies = new Cookies()
export const setCookies = cookies.set

const getAccessToken = () => {
  const access_token = cookies.get("access_token")
  return access_token
}

// const getRefreshToken = () => {
//   const refresh_token = cookies.get("refresh_token")
//   return refresh_token
// }

const config = {
  baseURL: process.env.REACT_APP_BASE_API_URL || "http://localhost:3001",
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*"
  }
}

const api = axios.create(config)

api.interceptors.request.use(
  (config) => {
    const access_token = getAccessToken()
    if (config.headers && access_token && !config.url?.includes("refresh_token"))
      config.headers["Authorization"] = `Bearer ${access_token ?? ""}`

    if (config.url?.includes("refresh_token")) (config as any)._retry = true

    config.withCredentials = true

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // const originalConfig = error.config

    // if (error.response) {
    //   if (error.response.status === 401 && !originalConfig._retry) {
    //     originalConfig._retry = true
    //     try {
    //       const data = await authService.refreshToken(getRefreshToken())
    //       if (!data) return Promise.reject(error)

    //       authService.registerRefreshToken(data.refresh_token)
    //       authService.registerAccessToken(data.access_token)

    //       api.defaults.headers.common["Authorization"] = data.access_token

    //       return api(originalConfig)
    //     } catch (_error: any) {
    //       if (_error.response && _error.response.data) {
    //         return Promise.reject(_error.response.data)
    //       }

    //       return Promise.reject(_error)
    //     }
    //   }
    // }

    return Promise.reject(error.response)
  }
)

export default api

export const parseError = (error: any, defaultMessage = "Something went wrong.") => {
  toast.error(error?.response?.data?.message || error?.data?.message || defaultMessage)
}

export const uploadFile = async (file: File) => {
  const extension = file.name.split(".").pop()
  const fileName = `${uuid()}.${extension}`
  const preSignResponse = await CMSService.presignedUrlForCMSImage(fileName)
  if (!preSignResponse) throw new Error("CMS upload failed")
  const { url } = preSignResponse
  if (!url) throw new Error("URL is required")

  await axios.put(url, file, {
    headers: { Accept: "*/*", "Content-Type": file.type }
  })
  return `https://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}.s3.amazonaws.com/cms_banners/${fileName}`
}

export const prepareData = async (data: PayloadToCreateEditCMSPayload): Promise<CreateCMSPayload | void> => {
  try {
    const updatedData = {
      active: data.active,
      tenant_id: data.tenant_id,
      name: data.name,
      headline: data.headline,
      subheadline: data.subheadline,
      link: data.link,
      banners_languages: cloneDeep(data.banners_languages) ?? [],
      type: data.type
    } as CreateCMSPayload
    if (data.id) {
      updatedData.id = data.id
    }
    if (data.order) {
      updatedData.order = +data.order
    }
    updatedData.banners_languages!.unshift({
      lang: LanguageTypesEnum.USA_English, // default English
      mobile: data.banner_image_mobile,
      desktop: data.banner_image,
      desktop_animation: data.banner_image_animation,
      is_desktop_animation: data.is_banner_image_animation,
      mobile_animation: data.banner_image_mobile_animation,
      is_mobile_animation: data.is_banner_image_mobile_animation,
      jackpots: data.jackpots
    })

    const languagesBannersPromises = (updatedData.banners_languages as BannerLanguage[]).map(async (b: BannerLanguage) => {
      if (b.desktop instanceof File) {
        // eslint-disable-next-line require-atomic-updates
        b.desktop = await uploadFile(b.desktop as File)
      }
      if (!b.mobile) {
        b.mobile = b.desktop
      } else if (b.mobile instanceof File) {
        // eslint-disable-next-line require-atomic-updates
        b.mobile = await uploadFile(b.mobile as File)
      }

      if (!b.desktop_animation) {
        b.desktop_animation = ""
      } else if (b.desktop_animation instanceof File) {
        // eslint-disable-next-line require-atomic-updates
        b.desktop_animation = await uploadFile(b.desktop_animation as File)
      }

      if (!b.mobile_animation) {
        b.mobile_animation = ""
      } else if (b.mobile_animation instanceof File) {
        // eslint-disable-next-line require-atomic-updates
        b.mobile_animation = await uploadFile(b.mobile_animation as File)
      }

      // eslint-disable-next-line require-atomic-updates
      b.jackpots = data.jackpots
      return b
    })
    updatedData.banners_languages = await Promise.all(languagesBannersPromises)
    updatedData.banner_image = updatedData.banners_languages[0].desktop as string
    updatedData.banner_image_mobile = updatedData.banners_languages[0].mobile as string
    return updatedData
  } catch (err) {
    console.error("Error when file uploading", err)
  }
}

const trimStrings = (object: Record<string, any>) => {
  for (const key in object) {
    if (typeof object[key] === "string") {
      object[key] = object[key].trim()
    }
    if (typeof object[key] === "object" && object[key] !== null && !Array.isArray(object[key])) {
      trimStrings(object[key])
    }
  }
}
export const prepareTenantSetupData = async (data: TenantConfigData): Promise<TenantConfigData | void> => {
  try {
    const updatedData = cloneDeep(data)
    updatedData.seals = updatedData.seals.filter((item) => item.trim())
    const imagePromises: Promise<{ key: string; values: string }>[] = []
    for (const key in updatedData) {
      // @ts-ignore
      if (updatedData[key] instanceof File) {
        // @ts-ignore
        imagePromises.push({ key, value: await uploadFile(updatedData[key]) })
      }
    }
    const uploadedFiles = await Promise.all(imagePromises)
    for (let i = 0; i <= uploadedFiles.length - 1; i++) {
      // @ts-ignore
      updatedData[uploadedFiles[i].key] = uploadedFiles[i].value
    }
    trimStrings(updatedData)
    return updatedData
  } catch (err) {
    console.error("Error when file uploading", err)
  }
}
