// eslint-disable-next-line import/named
import { FormikProps } from "formik"
import { FormValuesType } from "./ModalCMS"
import { BannerLanguageError } from "../../../services/cms/type"

export const getValidationLangBannerResults = (formik: FormikProps<FormValuesType>, i: number) => {
  const langErrorMessage = (formik.errors.banners_languages && (formik.errors.banners_languages[i] as BannerLanguageError)?.lang) || ""
  const isLangTouched = formik.touched.banners_languages && formik.touched.banners_languages[i] && formik.touched.banners_languages[i]?.lang
  const isLangInvalid = Boolean(isLangTouched && langErrorMessage)

  const desktopErrorMessage =
    formik.errors.banners_languages && (formik.errors.banners_languages[i] as BannerLanguageError)?.desktop
      ? (formik.errors.banners_languages[i] as BannerLanguageError).desktop
      : ""
  const isDesktopTouched =
    formik.touched.banners_languages && formik.touched.banners_languages[i] && formik.touched.banners_languages[i]?.desktop
  const isDesktopInvalid = Boolean(isDesktopTouched && desktopErrorMessage)

  return { langErrorMessage, isLangInvalid, desktopErrorMessage, isDesktopInvalid }
}
