import { FC, ReactElement } from "react"
import InputField from "../../inputs/InputField"
import { FieldArray, FormikProps } from "formik" // eslint-disable-line
import { TenantColorsData } from "../../../types/api"
import InputColor from "../../inputs/InputColor"

const WalletButtonGradientFields: FC = (): ReactElement => (
  <FieldArray
    name="gradient.walletButtonGradient"
    render={({ form }) => (
      <div className="mt-10">
        <h4>Gradient colors: wallet button</h4>
        {(form as FormikProps<TenantColorsData>).values.gradient.walletButtonGradient.map((_, index) => (
          <div key={index} className="flex items-end">
            <span className="mr-3">{index + 1}</span>
            <InputColor
              name={`gradient.walletButtonGradient.${index}`}
              value={(form as FormikProps<TenantColorsData>).values.gradient.walletButtonGradient[index]}
              onChange={(value: string) => form.setFieldValue(`gradient.walletButtonGradient.${index}`, value)}
            />
          </div>
        ))}
      </div>
    )}
  />
)

export default WalletButtonGradientFields
